<template>
  <div>

  <!-- add agent -->
    <v-container
      v-if="add"
      id="user-profile"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="9"
          style="background-color: whitesmoke"
        >
          <div class="text-h3 text-center font-weight-medium">
            Create User
          </div>
          <div class="text-subtitle-1 text-center font-weight-light">
            Complete user profile
          </div>
          <v-form>
            <v-container
              class="py-0"
              style="border-radius: 20px;"
            >
              <v-form
                v-model="valid"
                @submit.prevent="Createuser"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="id_number"
                      label="Agent ID Number"
                      class="purple-input"
                      autocomplete="off"
                      outlined
                      placeholder="00000000G00"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="mobile_number"
                      label="Agent Phone number"
                      class="purple-input"
                      autocomplete="off"
                      outlined
                      placeholder="0772000000"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="email"
                      label="Agent Email"
                      class="purple-input"
                      outlined
                      autocomplete="off"
                      :rules="[rules.required , rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="4"
                      md="3"
                      v-if="this.fbcmanager_state"
                  >
                    <v-select
                        v-model="agency"
                        :items="agencies"
                        item-text="name"
                        item-value="id"
                        label="Agency"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                    />
                  </v-col>
                  <v-col
                    cols="4"
                    md="3"
                >
                  <v-select
                      v-model="branch"
                      :items="all_branches"
                      item-text="branch_name"
                      item-value="id"
                      label="Branch"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"

                  />
                </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-switch
                        v-model="is_manager"
                        :label="`Is the Agent a Manager: ${is_manager.toString()}`"
                    ></v-switch>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-center"
                  >
                    <v-btn
                      :loading="loading"
                      color="primary"
                      solo
                      class="mr-0"
                      :disabled="!valid"
                      @click="createuser"
                    >
                      Save
                    </v-btn>
                    &nbsp; &nbsp;
                    <v-btn
                        color="red"
                        solo
                        class="mr-0"
                        @click="add = false, show_users=true"
                    >
                      Back
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-form>
          <br><br><br>
          <v-dialog
            v-model="dialog"
            max-width="290"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                User Creation<br> Successful
              </v-card-title>

              <v-card-text>
                A verification email has been sent to {{ email }}
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="green darken-1"
                  text
                  @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
            v-model="error_message"
            max-width="290"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                User Creation Failed
              </v-card-title>

              <v-card-text>
                The user details provided already exist
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="green darken-1"
                  text
                  @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <br><br><br>
    </v-container>
<!-- end of add agent -->
    <v-container v-if="show_users">
      <v-row>
        <v-col
          cols="12"
          md="12"
          style="background-color: whitesmoke"
        >
          <v-card-title>
            Users List
            <v-spacer />
            <v-spacer />

            <!-- add user -->
            <template>
              <div class="text-center">
                <v-btn
                  color="primary"
                  dark
                  @click="add = true, show_users = false"
                >
                  Add new user
                </v-btn>
              </div>
            </template>
            <!-- end of add user -->


            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="allusers"
            class="elevation-3"
            :search="search"
          >
            <template v-slot:item.is_active="{ item }">
              <v-chip
                v-if="item.is_active === true"
                color="primary"
                dark
              >
                ACTIVE
              </v-chip>
              <v-chip
                v-if="item.is_active === false"
                color="warning"
                dark
              >
                INACTIVE
              </v-chip>
            </template>
            <template v-slot:item.type="{ item }">
              <v-chip
                v-if="item.is_agent === true"
                color="primary"
                dark
              >
                CALLS CENTER
              </v-chip>
              <v-chip
                v-else-if="item.is_driver === true"
                color="primary"
                dark
              >
                DRIVER
              </v-chip>
              <v-chip
                v-else-if="item.is_staff === true"
                color="primary"
                dark
              >
                LEAD AGENT
              </v-chip>
              <v-chip
                v-else
                color="secondary"
                dark
              >
                UNASSIGNED
              </v-chip>
            </template>
            <template v-slot:item.view="{ item }">
              <v-btn
                fab
                small
                color="primary"
                @click="GoToEdit(item)"
              >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="Userdialog"
          persistent
          width="80%"
        >
          <v-card>
            <v-toolbar
              dark
              color="primary"
            >
              <v-btn
                icon
                dark
                @click="Userdialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />
              <v-toolbar-title class="align-center">
                Update User Details
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  @click="Userdialog = false"
                >
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="selected_phoneNumber"
                      label="Phone Number"
                      hint="user contact number"
                      disabled
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="4"
                  >
                    <v-text-field
                      v-model="selected_id_number"
                      label="User ID Number"
                      hint="user ID number"
                      disabled
                      outlined
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    sm="6"
                    md="8"
                  >
                    <v-text-field
                      v-model="selected_address"
                      label="User Email"
                      outlined
                      disabled
                      hint="User Email"
                    />
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    md="2"
                  >
                    <v-switch
                      v-model="selected_active"
                      inset
                      label="Is Active"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions
              class="justify-center"
            >
              <v-btn
                :loading = "loading"
                color="success"
                @click="Updateuser"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center">
          <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            />
          </v-overlay>
        </div>
      </v-row>
    </template>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
import { API } from '../../../api'

  export default {
    name: 'User',
    computed: {
      ...mapState(['base_url']),
    },
    data () {
      return {
        branch_list:[],
        agencies:[],
        branch:"",
        agency: "",
        all_branches:[],
        branch_name:"",
        branch_address:"",
        email: "",
        username: "",
        institution: "",
        id_number: "",
        mobile_number: "",
        fbcmanager_state: this.$store.state.is_fbcmanager,
        is_manager: false,
        overlay: false,
        dialog: false,
        error_message: false,
        success_message : false,
        valid: false,
        items: ['Is Manager', 'Not a Manager'],
        allusers: [],
        lead_agents: [],
        loading: false,
        Userdialog: false,
        first_name: null,
        last_name: null,
        user_id_number: null,
        address: null,
        headers: [
          {
            sortable: true,
            text: 'ID NUMBER',
            value: 'id_number',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'PHONE NUMBER',
            value: 'mobile_number',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'EMAIL',
            value: 'email',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'USERNAME',
            value: 'username',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'IS ACTIVE',
            value: 'is_active',
            class: 'primary--text title',
          },
          {
            sortable: false,
            text: 'VIEW',
            value: 'view',
            class: 'primary--text title',
          },
        ],
        users: [],
        types: [{ name: 'Call Center', value: 1 },
                { name: 'Lead Agent', value: 2 },
                { name: 'Driver', value: 3 }],
        add: false,
        add_branch:false,
        show_users:true,
        user_type: null,
        search: null,
        is_staff: false,
        is_agent: false,
        is_driver: false,
        selected_active: false,
        selected_is_staff: false,
        selected_is_agent: false,
        selected_is_driver: false,
        selected_is_supervisor: false,
        selected_firstname: null,
        selected_lastname: null,
        selected_id_number: null,
        selected_address: null,
        selected_phoneNumber: null,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      }
    },
    created () {
      this.getAgents()
      this.getAgency()
      this.getBranches()

    },
    methods: {
      async GoToEdit (val) {
        this.selected_firstname = val.firstname
        this.selected_lastname = val.lastname
        this.selected_id_number = val.id_number
        this.selected_address = val.address
        this.selected_phoneNumber = val.mobile_number
        this.selected_active = val.is_active
        this.selected_is_staff = val.is_staff
        this.selected_is_agent = val.is_agent
        this.selected_is_driver = val.is_driver
        this.supervisor_id = val.supervisor_id
        this.selected_is_supervisor = val.is_supervisor
        this.selected_id = val.id
        this.Userdialog = true
        console.log(val)
      },

    async getBranches () {
        const JWTToken = this.$store.getters.accessToken
        await axios.get(this.$base_url + '/api/auth/institution-branch/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.all_branches = res.data
              console.log("========= branches ==========")
              console.log(this.all_branches)
              console.log("========= branches ==========")

            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
      },
      async getAgency () {
        const JWTToken = this.$store.getters.accessToken
        await axios.get(this.$base_url + '/api/auth/institution-view/', { headers: { Authorization: `Bearer ${JWTToken}` } })
            .then(res => {
              if (res.status === 200) {
                this.agencies = res.data
                console.log(this.agencies)
              } else if (res.status === 202) {
                console.log(res.status)
              } else {
                this.overlay = false
                window.clearTimeout(this.timeoutID)
                this.$store.commit('clearUserData')
                alert('Your Session has ended')
                // this.$router.push("/login");
                window.location.href = '/login'
              }
            })
      },
      async createuser() {
        this.overlay = true;
        await new Promise(resolve => setTimeout(resolve, 2000));
        // try{
        let JWTToken = this.$store.getters.accessToken;
        console.log(this.$store.getters.accessToken);
        const payload = {
          "email": this.email,
          "password": 'Password1*',
          "username": this.email,
          "institution": this.agency,
          "id_number": this.id_number,
          "mobile_number": this.mobile_number,
          "is_manager": this.is_manager,
        };
        console.log(payload);

        var config = {
          method: "post",
          url: this.$base_url+"/api/auth/register/?branch="+this.branch,
          headers: {
            Authorization: `Bearer ${JWTToken}`,
            'Content-Type': 'application/json',
          },
          data: payload
        };

        axios(config)
            .then((response) => {
              if (response.status === 201) {
                console.log(response.data, 'im the user created')
                this.overlay = false;
                this.dialog = true;
                this.success_message = true;
                // alert('User created successfully and verification email has been to to the user.');
                // location.replace("/user_creation");
              }
            })
            .catch((error) => {
              if (error.status === 400) {
                this.error_message = true;
              }
              else {
                this.error_message = true;
              }
            });

        // await axios.post(this.$base_url+'/api/auth/register/', payload)
        //     .then(res => {
        //       if(res.status === 201){
        //         console.log(res.data, 'im the user created')
        //         this.overlay = false;
        //         this.dialog = true;
        //       }ƒ
        //     })
        //     .catch((error)=>{
        //       alert( error.response.data, 'System Error. Please try again.');
        //       console.log(error.response.data);
        //       location.replace("/user_creation");
        //     });

      },
      async Updateuser () {
        this.loading = true
        const UserData = {
          id: this.selected_id,
          is_staff: this.selected_is_staff,
          is_agent: this.selected_is_agent,
          is_driver: this.selected_is_driver,
          is_supervisor: this.selected_is_supervisor,
        }
        console.log(UserData)
        await new Promise(resolve => setTimeout(resolve, 2000))
        const JWTToken = this.$store.getters.accessToken
        axios.request({
          url: '/api/auth/register/',
          method: 'put',
          baseURL: API,
          headers: {
            Authorization: `Bearer ${JWTToken}`,
            'Content-Type': 'application/json',
          },
          data: UserData,
        }).then(res => {
          if (res.status === 201) {
            console.log(res.data)
            this.loading = false
            this.getAgents()
            // response data
            this.retrieve_policyDialog = false
            this.trans_summary = true
          } else if (res.status === 202) {
            this.responsedata = true
            this.overlay = false
          } else {
            this.overlay = false
            window.clearTimeout(this.timeoutID)
            this.$store.commit('clearUserData')
            alert('Your Session has ended')
            // this.$router.push("/login");
            window.location.href = '/login'
          }
        })
      },
      async getAgents () {
        const JWTToken = this.$store.getters.accessToken
        await axios.get(this.$base_url + '/api/auth/users/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.allusers = res.data
              console.log(res.data)
              console.log("Users here  they are")
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
      },
      async clear() {
        await this.getAgents();
        this.email = "";
        this.username = "";
        this.institution = "";
        this.id_number = "";
        this.mobile_number = "";
        this.is_manager = false;
        this.overlay = false;
        this.dialog = false;
        this.error_message = false;
        this.success_message = false;
        this.add = false;
        this.show_users = true;
      }
    },
  }
</script>
