<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Invoices by Month
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col  cols="12" md="12" style="background-color: whitesmoke">

              <v-card-title>
                Invoices
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      v-if="batch.length > 0"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <v-select
                    v-model="month"
                    :items="months"
                    item-text="month"
                    item-value="month"
                    single-line
                    hide-details
                    label="Select Invoices Month"
                    style="text-transform: uppercase"
                    @change="getInvoices()"
                />
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="batch"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >
                <template v-slot:item.Month="{ item }">
                  <span>{{ formatDate(item.Month) }}</span>
                </template>

                <template v-slot:item.Currency="{ item }">
                  <span>{{ item.Currency }}</span>
                </template>
                <template v-slot:item.amountBeforeTax="{ item }">
                  <span>{{ formatNumber(item.amountBeforeTax) }}</span>
                </template>
                <template v-slot:item.tax="{ item }">
                  <span>{{ formatNumber(item.tax) }}</span>
                </template>
                <template v-slot:item.amountAfterTax="{ item }">
                  <span>{{ formatNumber(item.amountAfterTax) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      color="primary"
                      dark
                      small
                      class="m-2"
                      @click="GoTo(item)"
                  >
                    <v-icon left>
                      mdi-file
                    </v-icon>
                    Invoice
                  </v-btn>
                  <v-btn
                      color="primary"
                      dark
                      small
                      class="m-2"
                      @click="viewEditDialog(item)"
                  >
                    <v-icon left>
                      mdi-pencil
                    </v-icon>
                    Update
                  </v-btn>
                  <v-btn
                      v-if="item.invoice_status =='POP SUBMITTED'"
                      color="primary"
                      dark
                      small
                      class="m-2"
                      @click="viewPOP(item)"
                  >
                    <v-icon left>
                      mdi-eye
                    </v-icon>
                    VIEW POP
                  </v-btn>
                </template>
                <template v-slot:item.invoice_status="{ item }">
                  <v-chip
                      v-if="item.invoice_status == 'PAID'"
                      color="success"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                  <v-chip
                      v-if="item.invoice_status == 'POP SUBMITTED'"
                      color="warning"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                  <v-chip
                      v-if="item.invoice_status == 'PENDING'"
                      color="error"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                  <v-chip
                      v-if="item.invoice_status == 'PARTIAL PAYMENT'"
                      color="purple"
                      dark
                  >
                    {{ item.invoice_status }}
                  </v-chip>
                </template>
                <template v-slot:item.pending_balance="{ item }">
                  <span>{{ item.pending_balance > 0 ? formatNumber(item.pending_balance) : '$0.00'}} </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <br><br><br>
        </v-col>
      </v-row>
      <v-dialog
          v-model="EditInvoice"
          persistent
          width="35%"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="EditInvoice = false"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer />
            <v-toolbar-title class="align-center">
              {{ selectedInfo.organisation_name }} - {{ selectedInfo.month }}
            </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-btn
                  dark
                  text
                  @click="EditInvoice = false"
              >
                Close
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-select
                      v-model="paymentStatus"
                      :items="['PAID', 'PENDING', 'PARTIAL PAYMENT']"
                      label="Select Invoice Status"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                  />
                </v-col>
                <v-col
                    cols="12"
                    sm="12"
                    md="12"
                >
                  <v-text-field
                      v-if="paymentStatus == 'PARTIAL PAYMENT'"
                      v-model="pendingBalance"
                      label="Pending Balance"
                      outlined
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions
              class="justify-center"
          >
            <v-btn
                :loading = "loading"
                color="primary"
                @click="UpdateInvoice()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
export default {
  name: 'Batch',
  data () {
    return {
      pendingBalance: 0.00,
      batch: [],
      months:[],
      selectedInfo: {},
      paymentStatus: '',
      EditInvoice: false,
      search: '',
      month: '',
      batchToRecall: '',
      overlay: false,
      successRecall: false,
      valid:false,
      confirmDelete: false,
      sortBy: 'month',
      sortDesc: true,
      headers: [
        { text: 'Organisation', value: 'organisation_name' },
        { text: 'PayeeCodes', value: 'payeeCode' },
        { text: 'Month', value: 'month' },
        { text: 'Count', value: 'count' },
        { text: 'Rate', value: 'rating' },
        { text: 'Total(Excl)', value: 'amountBeforeTax' },
        { text: 'Tax', value: 'tax' },
        { text: 'Total(Incl)', value: 'amountAfterTax' },
        { text: 'Status', value: 'invoice_status' },
        { text: 'Pending Amount', value: 'pending_balance' },
        { text: 'View', value: 'actions' },

      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Invoice',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  created () {
    this.getMonths()
  },
  methods: {
    async getInvoices() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/billing/get-allinvoices/' + this.month,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.batch = res.data
              console.log(this.batch)
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    async getMonths() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/billing/get-allmonths/',{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.months = res.data
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    async createExcel() {
      const filename = String('Invoices - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.batch
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'Invoices')
      xlsx.writeFile(wb, filename)
    },
    viewEditDialog (item) {
      this.selectedInfo = item
      this.EditInvoice = true
    },
    async UpdateInvoice(){
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      const data = {
        month: this.selectedInfo.month,
        organisation_name: this.selectedInfo.organisation_name,
        status: this.paymentStatus,
        pending_balance: this.pendingBalance,
      }
      console.log(data)
      axios.post('/das/billing/update-invoice/', data,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.overlay = false
              this.getInvoices()
            } else if (res.status === 404) {
              this.overlay = false
              this.getInvoices()
            } else {
              this.overlay = false
              this.getInvoices()
            }
          })
      this.clear()
    },
    clear () {
      this.pendingBalance = 0.00
      this.EditInvoice = false
      this.paymentStatus = ''
      this.selectedInfo = {}
      this.overlay = false
      this.getInvoices()
    },
    DownloadExcel(val){
      this.overlay = true
      console.log(val.DeductionCode)
      axios.get('/das/view_deductions/?code=' + val.DeductionCode + '&month=' + val.Month)
          .then(res => {
            if (res.status === 200) {
              const all_records = res.data
              const batch_records = all_records.filter((item) => {return (item.batchNumber === val.name)})
              batch_records.forEach((node) => delete node.id);
              batch_records.forEach((node) => delete node.paid);
              batch_records.forEach((node) => delete node.date_updated);
              batch_records.forEach((node) => delete node.date_created);
              const filename = String(val.DeductionCode + val.Month + '  - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
              const data = batch_records
              var ws = xlsx.utils.json_to_sheet(data)
              var wb = xlsx.utils.book_new()
              xlsx.utils.book_append_sheet(wb, ws, val.name)
              xlsx.writeFile(wb, filename)
              this.overlay = false
              this.successRecall = true
            } else if (res.status === 202) {
              console.log('202')
              this.overlay = false
            } else {
              this.overlay = false
              console.log('error')
            }
          })


    },
    GoTo(val){
      this.$router.push('/invoice/' + val.month + '-' + val.organisation_name) // navigate to /user/batch/123')
    },
    viewPOP(item){
      // window.location.href = "/das/" + item.pop;
      window.open("/das/media/" + item.pop,"_blank")
    },
    formatDate(val){
      val.replace(/\s/g, '');
      let finalDate = moment(val).format('MMM YYYY')
      return finalDate;
    },

    // format number to US dollar
    formatNumber(val){
      let finalNumber = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      return finalNumber;
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
