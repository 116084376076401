<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Uploaded batch records
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col  cols="10" md="10" style="background-color: whitesmoke">

              <v-card-title>
                Batch Records
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="batch"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >
                <template v-slot:item.owner="{ item }">
                  <span>Admin Account</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span v-if="item.name === null ">MIC2210010022</span>
                  <span v-else>{{ item.name }}</span>
                </template>
                <template v-slot:item.applicationDate="{ item }">
                  <span>{{ new Date(item.applicationDate).toLocaleString() }}</span>
                </template>
                <template v-slot:item.ssb_upload="{ item }">
                  <span v-if="item.batch_status === 'Processing' || item.batch_status === 'Pending'">--- --- ---</span>
                  <span v-else>{{ new Date(item.ssb_upload).toLocaleString() }}</span>
                </template>
                <template v-slot:item.ssb_download="{ item }">
                  <span v-if="item.batch_status === 'Pending'">--- --- ---</span>
                  <span v-else>{{ new Date(item.ssb_download).toLocaleString() }}</span>
                </template>
                <template v-slot:item.batch_status="{ item }">
                  <v-chip
                      v-if="item.batch_status === 'Pending'"
                      color="warning"
                      dark
                  >
                    PENDING
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Processing'"
                      color="purple"
                      dark
                  >
                    PROCESSING
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Mixed'"
                      color="primary"
                      dark
                  >
                    MIXED
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Success'"
                      color="success"
                      dark
                  >
                    SUCCESS
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Failed'"
                      color="danger"
                      dark
                  >
                    FAILED
                  </v-chip>
<!--                  <v-chip-->
<!--                      v-else-->
<!--                      color="danger"-->
<!--                      dark-->
<!--                  >-->
<!--                    RECALLED-->
<!--                  </v-chip>-->
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      v-if="item.batch_status === 'Success' || item.batch_status === 'Failed' || item.batch_status === 'Mixed'"
                      color="primary"
                      dark
                      small
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Excel
                  </v-btn>
                  &nbsp;
                  <v-btn
                      v-if="item.batch_status === 'Success' || item.batch_status === 'Failed' || item.batch_status === 'Mixed'"
                      color="success"
                      dark
                      small
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Source
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <br><br><br>
        </v-col>
      </v-row>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
export default {
  name: 'Batch',
  data () {
    return {
      batch: [],
      search: '',
      overlay: false,
      valid:false,
      sortBy: 'applicationDate',
      sortDesc: true,
      headers: [
        { text: 'Created by', value: 'owner' },
        { text: 'BatchID', value: 'name' },
        { text: 'No of Records', value: 'count' },
        { text: 'Response', value: 'batch_status' },
        { text: 'Booked', value: 'applicationDate' },
        { text: 'Processing Start', value: 'ssb_download' },
        { text: 'Processing End', value: 'ssb_upload' },
        { text: '', value: 'actions' },
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Uploaded batch',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  created () {
   this.getBatch()
  },
  methods: {
    async getBatch() {
      console.log('im here')
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/batch/?organisation=SSB')
          .then(res => {
            if (res.status === 200) {
              this.batch = res.data
              console.log(this.batch)
            } else if (res.status === 404) {
              console.log('404')
            } else {
              console.log('error')
            }
          })
    },
    async createExcel() {
      const filename = String('BatchSummary - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.batch
      console.log("createExcel method called")
      console.log(this.batch)
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'BatchSummary')
      xlsx.writeFile(wb, filename)
    },
    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },
    clear () {
      this.trans_summary = false
      this.$router.push('/user/dashboard')
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
