<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Monthly Statements
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col  cols="10" md="10" style="background-color: whitesmoke">

              <v-card-title>
                Monthly Statements
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      v-if="false"
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>
                <v-menu
                    v-if="false"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select Invoice Period"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>

                  <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="secondary"
                        @click="OnDateChange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="batch"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >
                <template v-slot:item.Month="{ item }">
                  <span>{{ formatDate(item.Month) }}</span>
                </template>

                <template v-slot:item.Currency="{ item }">
                  <span>{{ item.Currency }}</span>
                </template>
                <template v-slot:item.bill="{ item }">
                  <span>{{ formatNumber(item.bill) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      color="primary"
                      dark
                      small
                      class="m-2"
                      @click="DownloadCSV(item)"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    CSV
                  </v-btn>
                  <v-btn
                      color="primary"
                      dark
                      small
                      class="pl-3 ml-2"
                      @click="DownloadDD1(item)"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    DD1
                  </v-btn>

                  <v-btn
                      color="primary"
                      dark
                      small
                      class="pl-3 ml-2"
                      @click="DownloadPR1(item)"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    PR1
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <br><br><br>
        </v-col>
      </v-row>
      <v-dialog
          v-model="confirmDelete"
          persistent
          width="400"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Confirm Delete
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #F44336 !important"
            >
              You about to recall batch {{batchToRecall}}
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="5em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="confirmDelete=false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="red"
                      @click="RecallBatch"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="successRecall"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center align-items-center justify-content-center align-self-center">
              Download Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h4
                style="color: #4caf50"
            >
              Statement Downloaded <br> kindly check your excel application.
            </h4>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
export default {
  name: 'Batch',
  data () {
    return {
      batch: [],
      search: '',
      batchToRecall: '',
      overlay: false,
      successRecall: false,
      valid:false,
      confirmDelete: false,
      sortBy: 'Month',
      sortDesc: true,
      headers: [
        { text: 'DeductionCode', value: 'DeductionCode' },
        { text: 'Month', value: 'Month' },
        { text: 'Currency', value: 'Currency' },
        // { text: 'Bill Amount', value: 'bill' },
        { text: 'No of Records', value: 'count' },
        { text: 'Download', value: 'actions' },
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Uploaded batch',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  created () {
   this.getStatement()
  },
  methods: {
    async getStatement() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/get-statement/?organisation=' + this.$store.getters.OrgId)
          .then(res => {
            if (res.status === 200) {
              this.batch = res.data
              console.log(this.batch)
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    async createExcel() {
      const filename = String('BatchSummary - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.batch
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'BatchSummary')
      xlsx.writeFile(wb, filename)
    },
    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },
    clear () {
      this.batchToRecall = ''
      this.successRecall = false
      this.getBatch()
    },
    PromptDelete(val){
      this.batchToRecall = val.name
      this.confirmDelete = true
    },
    RecallBatch(){
      axios.get('/das/recall/?id=' + this.batchToRecall)
          .then(res => {
            if (res.status === 200) {
              this.confirmDelete = false
              this.successRecall = true

            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    DownloadExcel(val){
      this.overlay = true
      console.log(val.DeductionCode)
      axios.get('/das/view_deductions/?code=' + val.DeductionCode + '&month=' + val.Month)
          .then(res => {
            if (res.status === 200) {
              const all_records = res.data
              const batch_records = all_records.filter((item) => {return (item.batchNumber === val.name)})
              batch_records.forEach((node) => delete node.id);
              batch_records.forEach((node) => delete node.paid);
              batch_records.forEach((node) => delete node.date_updated);
              batch_records.forEach((node) => delete node.date_created);
              const filename = String(val.DeductionCode + val.Month + '  - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
              const data = batch_records
              var ws = xlsx.utils.json_to_sheet(data)
              var wb = xlsx.utils.book_new()
              xlsx.utils.book_append_sheet(wb, ws, val.name)
              xlsx.writeFile(wb, filename)
              this.overlay = false
              this.successRecall = true
            } else if (res.status === 202) {
              console.log('202')
              this.overlay = false
            } else {
              this.overlay = false
              console.log('error')
            }
          })


    },
    DownloadCSV(val){
      this.overlay = true
      axios.get('/das/get-statement-by-type/' + val.DD_id + '/DD1')
          .then(res => {
            if (res.status === 200) {
              console.log(res.data)
              let text = res.data[0]['fileinfo'];
              const dataInJSON = [];
              text = text.substring(text.indexOf("DED0000") + 0);
              text = text.split('UTL00')[0];
              const lines = text.split('\n');
              for (let line = 0; line < lines.length - 1; line++) {
                let IdNumber = lines[line].substring(51,66).trim();
                let Name = lines[line].substring(66,96).trim();
                let Amount = lines[line].substring(96,108).trim();
                let Date = lines[line].substring(108,117).trim();
                let EcNumber = lines[line].substring(118,126).trim();
                let Reference = lines[line].substring(126,150).trim();
                let formattedDate = String(moment(Date).format('YYYY/MM/DD'));
                let formattedAmount = String(parseFloat(Amount) / 100)
                const myObj = {
                  "DeductionCode": val.DeductionCode,
                  "Reference": Reference,
                  "IdNumber": IdNumber,
                  "EcNumber": EcNumber,
                  "FullName": Name,
                  "Date": formattedDate,
                  "Month": val.Month,
                  "Amount": formattedAmount,
                };
                dataInJSON.push(myObj);
              }
              console.log(dataInJSON)
              const filename = String(val.DeductionCode + val.Month + '.xlsx')
              const data = dataInJSON
              var ws = xlsx.utils.json_to_sheet(data)
              var wb = xlsx.utils.book_new()
              xlsx.utils.book_append_sheet(wb, ws, val.name)
              xlsx.writeFile(wb, filename)
              this.overlay = false
            }
            else if (res.status === 202) {
              console.log('202')
              this.overlay = false
            } else {
              this.overlay = false
              console.log('error')
            }
          })
    },
    DownloadPR1(val){
      this.overlay = true
      axios.get('/das/get-statement-by-type/' + val.PR_id + '/PR1')
          .then(res => {
            if (res.status === 200) {
              let text = res.data[0]['fileinfo'];
              const element = document.createElement('a');
              element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data[0]['fileinfo']));
              element.setAttribute('download', res.data[0]['file_name']);
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
              this.overlay = false
              this.overlay = false
            }
            else if (res.status === 202) {
              console.log('202')
              this.overlay = false
            } else {
              this.overlay = false
              console.log('error')
            }
          })


    },
    DownloadDD1(val){
      this.overlay = true
      axios.get('/das/get-statement-by-type/' + val.DD_id + '/DD1')
          .then(res => {
            if (res.status === 200) {
              let text = res.data[0]['fileinfo'];
              const element = document.createElement('a');
              element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(res.data[0]['fileinfo']));
              element.setAttribute('download', res.data[0]['file_name']);
              element.style.display = 'none';
              document.body.appendChild(element);
              element.click();
              document.body.removeChild(element);
              this.overlay = false
              this.overlay = false
            }
            else if (res.status === 202) {
              console.log('202')
              this.overlay = false
            } else {
              this.overlay = false
              console.log('error')
            }
          })
    },
    generateRef(val){
      let ref = 'REC' + val
      return ref;
    },
    formatDate(val){
      val.replace(/\s/g, '');
      let finalDate = moment(val).format('MMM YYYY')
      return finalDate;
    },
    // format number to US dollar
    formatNumber(val){
      let finalNumber = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' })
      return finalNumber;
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
