<template>
  <div>

    <!-- add agent -->
    <v-container
        v-if="add"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row justify="center">
        <v-col
            cols="12"
            md="9"
        >
          <div class="text-h3 text-center font-weight-medium">
            Create User
          </div>
          <div class="text-subtitle-1 text-center font-weight-light">
            Complete user profile
          </div>
          <v-form
              style="border-radius: 15px;"
          >
            <v-container
                class="py-0"
                style="border-radius: 20px;"
            >
              <v-form
                  v-model="valid"
                  @submit.prevent="Createuser"
              >
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="first_name"
                        label="First Name"
                        class="purple-input"
                        autocomplete="off"
                        outlined
                        placeholder="John"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="last_name"
                        label="Last Name"
                        class="purple-input"
                        autocomplete="off"
                        outlined
                        placeholder="Doe"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="username"
                        label="Username"
                        class="purple-input"
                        autocomplete="off"
                        outlined
                        placeholder="username"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="mobile_number"
                        label="User mobile number"
                        class="purple-input"
                        outlined
                        autocomplete="off"
                        placeholder="0772000000"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="12"
                  >
                    <v-text-field
                        v-model="email"
                        label="User email address"
                        class="purple-input"
                        autocomplete="off"
                        outlined
                        placeholder="user@email.com"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="8"
                      md="8"
                  >
                    <v-select
                        v-model="organisation"
                        :items="all_organisations"
                        item-text="name"
                        item-value="id"
                        label="Organisation"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                    />
                  </v-col>
                  <v-col
                      v-if="false"
                      cols="4"
                      md="4"
                  >
                    <v-btn
                        :loading="loading"
                        color="primary"
                        solo
                        class="mr-0 mt-2"
                        @click="AddOrganisation = true"
                    >
                     Add Organisation
                    </v-btn>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-switch
                        v-model="is_bss"
                        :label="`Is the User an Admin: ${is_bss.toString()}`"
                    ></v-switch>
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-switch
                        v-model="is_ssb"
                        :label="`Is the User for SSB: ${is_ssb.toString()}`"
                    ></v-switch>
                  </v-col>
                  <v-col
                      cols="12"
                      class="text-center"
                  >
                    <v-btn
                        :loading="loading"
                        color="primary"
                        solo
                        class="mr-0"
                        :disabled="!valid"
                        @click="createuser"
                    >
                      Save
                    </v-btn>
                    &nbsp; &nbsp;
                    <v-btn
                        color="red"
                        solo
                        class="mr-0"
                        @click="add = false, show_users=true"
                    >
                      Back
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-form>
          <br><br><br>
          <v-dialog
              v-model="dialog"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                User Creation Successful
              </v-card-title>

              <v-card-text class="text-center">
                The new user password is <b>{{ created_user_id }}</b><br>
                <v-btn
                    class="ma-2"
                    small
                    outlined
                    color="primary"
                    @click="copyText()">
                  Copy Password
                </v-btn><br>
                <v-snackbar
                    :timeout="-1"
                    :value="IsCopied"
                    bottom
                    color="success"
                    outlined
                    right
                >
                  Password Copied
                </v-snackbar>
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="Orgdialog"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                Organisation Creation<br> Successful
              </v-card-title>

              <v-card-text>
                Organisation has been added to the list for selection
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="Orgdialog = false"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="error_message"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                User Creation Failed
              </v-card-title>

              <v-card-text>
                The user details provided already exist
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <br><br><br>
    </v-container>
    <!-- end of add agent -->
    <v-container v-if="show_users">
      <v-row>
        <v-col
            cols="12"
            md="12"
            style="background-color: whitesmoke"
        >
          <v-card-title class="pb-2">
            Accounts List
            <v-spacer />
            <!-- add user -->
            <div class="text-center pb-1">
              <template>
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    outlined
                    color="primary"
                    @click="show_users = false, add = true"
                >
                  <v-icon color="primary">
                    mdi-account-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-btn
                  v-if="false"
                  color="primary"
                  dark
                  @click=" show_users = false, add = true"
              >
                Add new account
              </v-btn>
            </div>
            <!-- end of add user -->
            <v-spacer />
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="pb-2"
            />
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="allusers"
              class="elevation-3"
              :search="search"
          >
            <template v-slot:item.created_at="{ item }">
              <span>{{ new Date(item.created_at).toLocaleString() }}</span>
            </template>
            <template v-slot:item.is_active="{ item }">
              <v-chip
                  v-if="item.is_active === true"
                  color="primary"
                  dark
              >
                ACTIVE
              </v-chip>
              <v-chip
                  v-if="item.is_active === false"
                  color="warning"
                  dark
              >
                INACTIVE
              </v-chip>
            </template>
            <template v-slot:item.view="{ item }">
              <v-btn
                  fab
                  small
                  color="primary"
                  @click="GoToEdit(item)"
              >
                <v-icon>
                  mdi-lock-reset
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.user_type="{ item }">
              <v-chip
                  :color="item.user_type === 'APPROVER' ? 'success' : 'deep-purple accent-4'"
                  outlined
                  pill
              >
                {{ item.user_type }}
                <v-icon x-small right @click="GoToUserType(item)">
                  mdi-pencil
                </v-icon>
              </v-chip>
            </template>

          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="reset_confirm"
            max-width="290"
            persistent
        >
          <v-card>
            <v-card-title class="text-h5">
              Password Reset Request
            </v-card-title>

            <v-card-text class="text-center">
              Are you sure you want to reset password for <b>{{ selected_id_number }}</b>
            </v-card-text>

            <v-card-actions>
              <v-btn
                  color="green darken-1"
                  text
                  @click="reset_confirm = false"
              >
                Cancel
              </v-btn>
              <v-spacer />

              <v-btn
                  color="green darken-1"
                  text
                  @click="Reset_Password()"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="reset_success"
            max-width="290"
            persistent
        >
          <v-card>
            <v-card-title class="text-h5">
              Password Reset Successful
            </v-card-title>

            <v-card-text class="text-center">
              The new password for {{ selected_id_number }} is <b> {{ created_user_id }}</b><br>
              <v-btn
                  class="ma-2"
                  small
                  outlined
                  color="primary"
                  @click="copyText()">
                Copy Password
              </v-btn><br>
              <v-snackbar
                  :timeout="-1"
                  :value="IsCopied"
                  bottom
                  color="success"
                  outlined
                  right
              >
                Password Copied
              </v-snackbar>

            </v-card-text>

            <v-card-actions
            >
              <v-spacer></v-spacer>
              <v-btn
                  outlined
                  color="green darken-1"
                  text
                  @click="clear"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="UserType"
            persistent
            width="40%"
        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="UserType = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />
              <v-toolbar-title class="align-center">
                Update User Details
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="UserType = false"
                >
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-select
                        v-model="user_type"
                        :items="['INITIATOR','APPROVER']"
                        label="Select User Type"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions
                class="justify-center"
            >
              <v-btn
                  :loading = "loading"
                  color="primary"
                  @click="Updateuser"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="AddOrganisation"
            persistent
            width="30%"
        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="AddOrganisation = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />
              <v-toolbar-title class="align-center">
                Add Organisation Name
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="AddOrganisation = false"
                >
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        v-model="organisation_name"
                        label="Organisation Name"
                        hint="add a new organisation"
                        outlined
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-select
                        v-model="billingRate"
                        :items="billingRates"
                        item-text="deductionCode"
                        item-value="deductionCode"
                        label="Select Deduction Code"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"
                        @change="getPayeeCodes()"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions
                class="justify-center"
            >
              <v-btn
                  :loading = "loading"
                  color="primary"
                  @click="SaveOrganisation()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center">
          <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            />
          </v-overlay>
        </div>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { API } from '../../../api'
import {getUserIP} from "@/services/userIp";

export default {
  name: 'User',
  computed: {
    ...mapState(['base_url']),
  },
  data () {
    return {
      IsCopied: false,
      reset_confirm: false,
      reset_success: false,
      all_organisations:[],
      agencies:[],
      organisation_name:"",
      organisation:"",
      agency: "",
      all_branches:[],
      branch_name:"",
      branch_address:"",
      email: "",
      username: "",
      institution: "",
      id_number: "",
      mobile_number: "",
      fbcmanager_state: this.$store.state.is_fbcmanager,
      Orgdialog: false,
      is_bss: false,
      is_ssb: false,
      overlay: false,
      dialog: false,
      error_message: false,
      success_message : false,
      valid: false,
      items: ['Is Manager', 'Not a Manager'],
      allusers: [],
      lead_agents: [],
      loading: false,
      AddOrganisation: false,
      UserType: false,
      first_name: null,
      last_name: null,
      user_id_number: null,
      address: null,
      headers: [
        {
          sortable: true,
          text: 'NAME',
          value: 'fullname',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'USERNAME',
          value: 'id_number',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'USER TYPE',
          value: 'user_type',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'STATUS',
          value: 'is_active',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'ORGANISATION',
          value: 'organisation_name',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'CREATED',
          value: 'created_at',
          class: 'primary--text title',
        },

        {
          sortable: false,
          text: 'RESET',
          value: 'view',
          class: 'primary--text title',
        },
      ],
      users: [],
      add: false,
      add_branch:false,
      show_users:true,
      user_type: null,
      search: null,
      is_staff: false,
      is_agent: false,
      is_driver: false,
      selected_active: false,
      selected_is_staff: false,
      selected_is_agent: false,
      selected_is_driver: false,
      selected_is_supervisor: false,
      selected_firstname: null,
      selected_lastname: null,
      selected_id_number: null,
      selected_address: null,
      selected_phoneNumber: null,
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  created () {
    this.getUsers()
    this.getOrganisations()

  },
  methods: {
    copyText() {
      navigator.clipboard.writeText(this.created_user_id);
      this.IsCopied = true;
    },
    async getBillingRates () {
      const JWTToken = this.$store.getters.accessToken
      await axios.get('/das/get-organisations/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.all_organisations = res.data
              console.log(this.all_organisations)
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },

    async GoToEdit (val) {
      this.selected_id_number = val.id_number
      this.selected_id = val.id
      this.reset_confirm = true
      console.log(val)
    },
    async GoToUserType (val) {
      this.user_type = val.user_type
      this.selected_id = val.id
      this.UserType = true
      console.log(val)
    },


    async getOrganisations () {
      const JWTToken = this.$store.getters.accessToken
      await axios.get('/das/get-organisations/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.all_organisations = res.data
              console.log(this.all_organisations)
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async createuser() {
      this.overlay = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      // try{
      let JWTToken = this.$store.getters.accessToken;
      console.log(this.$store.getters.accessToken);
      const payload = {
        "email": this.email,
        "id_number": this.username,
        "username": this.username,
        "password": this.username,
        "mobile_number": this.mobile_number,
        "firstname": this.first_name,
        "lastname": this.last_name,
        "is_verified": true,
        "organisation": this.organisation,
        "is_bss": this.is_bss,
        "is_ssb": this.is_ssb,
      };
      console.log(payload);
      var config = {
        method: "post",
        url: "/das/auth/register/",
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: payload
      };

      axios(config)
          .then((response) => {
            if (response.status === 201) {
              console.log(response.data, 'im the user created')
              this.created_user_id = response.data.data.user_id
              this.overlay = false;
              this.dialog = true;
              this.success_message = true;
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              this.error_message = true;
            }
            else {
              this.error_message = true;
            }
          });

      // await axios.post(this.$base_url+'/api/auth/register/', payload)
      //     .then(res => {
      //       if(res.status === 201){
      //         console.log(res.data, 'im the user created')
      //         this.overlay = false;
      //         this.dialog = true;
      //       }ƒ
      //     })
      //     .catch((error)=>{
      //       alert( error.response.data, 'System Error. Please try again.');
      //       console.log(error.response.data);
      //       location.replace("/user_creation");
      //     });

    },
    async SaveOrganisation() {
      this.AddOrganisation = false;
      this.overlay = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      // try{
      let JWTToken = this.$store.getters.accessToken;
      console.log(this.$store.getters.accessToken);
      const payload = {
        "userIP": getUserIP(),
        "name": this.organisation_name,
      };
      console.log(payload);
      const config = {
        method: "post",
        url: "/das/add-organisation/",
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: payload
      };
      axios(config)
          .then((response) => {
            if (response.status === 201) {
              console.log(response.data, 'im the organisation created')
              this.getOrganisations()
              this.overlay = false
              this.Orgdialog = true
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              this.overlay = false;
            }
            else {
              this.overlay = false;
            }
          });

    },
    async Updateuser () {
      this.loading = true
      const UserData = {
        user_id: this.selected_id,
        user_type: this.user_type
      }
      console.log(UserData)
      await new Promise(resolve => setTimeout(resolve, 2000))
      const JWTToken = this.$store.getters.accessToken
      axios.request({
        url: '/das/auth/update_user/',
        method: 'post',
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: UserData,
      }).then(res => {
        if (res.status === 200) {
          console.log(res.data)
          this.loading = false
          this.getUsers()
          this.UserType = false
        } else if (res.status === 202) {
          this.overlay = false
        } else {
          this.overlay = false
          window.clearTimeout(this.timeoutID)
          this.$store.commit('clearUserData')
          alert('Your Session has ended')
          // this.$router.push("/login");
          window.location.href = '/login'
        }
      })
    },
    async Reset_Password(){
      this.reset_confirm = false;
      this.overlay = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      // try{
      let JWTToken = this.$store.getters.accessToken;
      console.log(this.$store.getters.accessToken);
      const payload = {
        "userIP": getUserIP(),
        "id": this.selected_id_number,
      };
      console.log(payload);
      const config = {
        method: "post",
        url: "/das/auth/reset_password/",
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: payload
      };

      axios(config)
          .then((response) => {
            if (response.status === 200) {
              this.created_user_id = response.data.data.user_id
              this.overlay = false
              this.reset_success = true
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              this.overlay = false;
            }
            else {
              this.overlay = false;
            }
          });

    },

    async getUsers () {
      const JWTToken = this.$store.getters.accessToken
      await axios.get('/das/auth/get_users/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.allusers = res.data
              console.log(res.data)
              console.log("Users here  they are")
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async clear() {
      this.IsCopied = false
      await this.getUsers();
      this.email = "";
      this.selected_id_number ="";
      this.reset_confirm = false;
      this.reset_success = false;
      this.last_name = "";
      this.first_name = "";
      this.organisation = "";
      this.username = "";
      this.institution = "";
      this.id_number = "";
      this.mobile_number = "";
      this.is_manager = false;
      this.overlay = false;
      this.dialog = false;
      this.error_message = false;
      this.success_message = false;
      this.add = false;
      this.show_users = true;
    }
  },
}
</script>
