<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Upload Monthly Statement
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0"
          >
            <v-form
                v-model="valid"
                class="rounded elevation-2 pa-3"
                @submit.prevent="UploadFile"
            >
              <v-row class="mt-2" style="min-width: 400px">
                <v-col
                    cols="12"
                    md="12"
                >
                  <input class="mt-3" type="file" ref="myFile" @change="UploadMonthlyFiles" hidden>
                  <v-file-input
                      @change="UploadMonthlyFiles"
                      v-model="files"
                      placeholder="Upload your document"
                      label="File input"
                      multiple
                      outlined
                      prepend-icon="mdi-paperclip"
                      :rules="[rules.required]"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                          small
                          label
                          color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-col>
                <v-col
                    cols="12"
                    class="text-center mb-1"
                >
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="UploadFile"
                  >
                    Upload
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col cols="10" md="10" style="background-color: whitesmoke; justify-content: center">
              <v-card-title>
                Preview
                <v-spacer></v-spacer>
                <br>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="uploadedData"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
          v-model="successUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Upload Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              Records already exist
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
export default {
  name: 'Booking',
  data () {
    return {
      dasCodes: [],
      overlay: false,
      valid:false,
      files:[],
      ssbData: [],
      successUpload:false,
      errorUpload: false,
      dasCurrency: '',
      deductionCode: '',
      payeeCode: '',
      uploadedData:[],
      headers: [
          { text: 'Reference', value: 'Reference' },
          { text: 'EcNumber', value: 'EcNumber' },
          { text: 'DeductionCode', value: 'DeductionCode' },
          { text: 'IdNumber', value: 'IdNumber' },
          { text: 'Date', value: 'Date' },
          { text: 'Amount', value: 'Amount' },
          { text: 'Month', value: 'Month' },
        ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'Upload',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Monthly Statement',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  created () {
   this.getDasCodes()
  },
  methods: {
    async getDasCodes() {
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/payeeCode_and_currency/?organization_id=' + this.$store.getters.OrgId)
          .then(res => {
            if (res.status === 200) {
              this.dasCodes = res.data
              console.log(this.dasCodes)
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    async populateDasCodes() {
      const dascode = this.dasCodes.find(item => item.deductionCode === this.deductionCode)
      this.payeeCode = dascode.payeeCode
      this.dasCurrency = dascode.currency
    },


    async SSBUploadFiles() {
      const that = this
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        let filExt = file.name.split('.')[1]
        const payeeCode = file.name.split('.')[0]
        // let payeeCode = filename.slice(-5)
        filExt = filExt.split(' ')[0]
        console.log(filExt)
        if (filExt === 'dd1') {
          let reader = new FileReader();
          reader.readAsText(file, "UTF-8");
          reader.onload = evt => {
            this.text = evt.target.result;
            const firstLine = this.text.substring(0, this.text.indexOf("DED0000"));
            const payeeCode = firstLine.split(' ')[4]
            let month = this.text.substring(3,9);
            console.log(month)
            this.text = this.text.substring(this.text.indexOf("DED0000") + 0);
            this.text = this.text.split('UTL00')[0];
            this.text = "DED0000   DAS0             DAS00000000000000000000000000000DAS    Name L                       DAS00DAS000000000DAS  DAS0000000000DAS0000  \n" + this.text
            const dataInJSON = dataToJson.txt({data: this.text}).toJson();
            console.log(dataInJSON)
            dataInJSON.forEach((node) => delete node.DED0000);
            dataInJSON.forEach((node) => delete node.DAS0);
            dataInJSON.forEach((node) => node.Reference = this.sanitizeRef(node.DAS0000000000DAS0000));
            dataInJSON.forEach((node) => node.EcNumber = this.sanitizeEC(node.DAS0000000000DAS0000));
            dataInJSON.forEach((node) => delete node.DAS0000000000DAS0000);
            dataInJSON.forEach((node) => node.IdNumber = this.sanitizeID(node.DAS00000000000000000000000000000DAS));
            dataInJSON.forEach((node) => delete node.DAS00000000000000000000000000000DAS);
            dataInJSON.forEach((node) => node.Date = this.sanitizeDate(node.DAS00DAS000000000DAS));
            dataInJSON.forEach((node) => node.Amount = this.sanitizeAmount(node.DAS00DAS000000000DAS));
            dataInJSON.forEach((node) => node.FullName = this.sanitizeName(node.Name,node.L));
            dataInJSON.forEach((node) => delete node.Name,);
            dataInJSON.forEach((node) => delete node.L);
            dataInJSON.forEach((node) => delete node.DAS00DAS000000000DAS);
            dataInJSON.forEach((node) => node.DeductionCode = payeeCode);
            dataInJSON.forEach((node) => node.Month = month);
            that.uploadedData = this.uploadedData.concat(dataInJSON)
          }
          reader.onerror = evt => {
            console.error(evt);
          }
        }
      }

    },
    async UploadFileOrg(){
      this.overlay = true
      console.log(this.uploadedData)
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const JWTToken = this.$store.getters.accessToken
      await axios
          .post("/das/post-monthly-statements/", this.uploadedData,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then((response) => {
            console.log(response)
            this.overlay = true;
            if (response.status === 201) {
              this.overlay = false;
              this.successUpload = true;
              // this.$router.push("/user/dashboard").catch(() => {})
            } else if (response.status === 404){
              this.errorUpload = true;
              this.overlay = false;
            }
            else {
              this.overlay = false;
              this.errorUpload = true;
              console.log(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 404) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.errorUpload = true;
            }else{
              this.overlay = false;
              this.errorUpload = true;
            }
          });

    },
    async UploadFile(){
      this.overlay = true
      console.log(this.ssbData)
      await new Promise((resolve) => setTimeout(resolve, 3000));
      const JWTToken = this.$store.getters.accessToken
      await axios
          .post("/das/post-monthly-statements/", this.ssbData,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then((response) => {
            console.log(response)
            this.overlay = true;
            if (response.status === 201) {
              this.overlay = false;
              this.successUpload = true;
              // this.$router.push("/user/dashboard").catch(() => {})
            } else if (response.status === 404){
              this.errorUpload = true;
              this.overlay = false;
            }
            else {
              this.overlay = false;
              this.errorUpload = true;
              console.log(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 404) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.errorUpload = true;
            }else{
              this.overlay = false;
              this.errorUpload = true;
            }
          });

    },
    sanitizeRef(val){
      val = val.substring(8);
      return val;
    },
    sanitizeName(val1,val2){
      let fullname = val1 + "" + val2;
      return fullname;
    },
    sanitizeEC(val){
      val = val.substring(0,8);
      return val;
    },
    sanitizeID(val){
      val = val.substring(24);
      return val;
    },
    sanitizeDate(val){
      val = val.substring(12);
      val = moment(val).format('YYYY/MM/DD')
      return val;
    },
    sanitizeAmount(val){
      val = val.substring(0,12);
      val = +val / 100
      val.toString()
      return val;
    },

    async UploadMonthlyFiles() {
      console.log(this.files.length)
      const dataInJSON = [];
      const that = this
      for (let i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        const payeeCode = file.name.split('.')[0].split('DD')[1]
        let FileExt = file.name.split('.')[1]
        console.log(FileExt)
        let reader = new FileReader();
        reader.readAsText(file, "UTF-8");
        if(FileExt =='dd1'){
          reader.onload = evt => {
            this.text = evt.target.result;
            let month = this.text.substring(3,9);
            console.log(month)
            const lines = this.text.split('\n');
            let line_required = lines[lines.length - 3]
            let count = line_required.substring(1,12).trim()
            let amount = line_required.substring(45,59).trim()
            const SSBfile = {file_name: file.name, payeeCode:payeeCode, fileinfo: this.text, month: month, file_type: 'DD1'}
            that.ssbData = this.ssbData.concat(SSBfile)
          }
        }
        if(FileExt =='pr1'){
          reader.onload = evt => {
            this.text = evt.target.result;
            let mon = this.text.substring(3,5);
            let th = this.text.substring(6,10);
            let month = String(th) + String(mon)
            const lines = this.text.split('\n');
            let line_required = lines[lines.length - 3]
            let count = line_required.substring(1,12).trim()
            let amount = line_required.substring(45,59).trim()
            const SSBfile = {file_name: file.name, payeeCode:payeeCode, fileinfo: this.text, month: month, count: count, amount: amount, file_type: 'PR1'}
            that.ssbData = this.ssbData.concat(SSBfile)
          }
        }


        reader.onerror = evt => {
          console.error(evt);
        }
      }
    },

    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },
    goTo(){
      this.$router.push('/user/upload-statement')
    },
    clear () {
      this.trans_summary = false
      this.$router.push('/user/dashboard')
    },

  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
