<template>
  <div>

    <!-- add agent -->
    <v-container
        v-if="add"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row justify="center">
        <v-col
            cols="12"
            md="9"
        >
          <div class="text-h3 text-center font-weight-medium">
            Add DAS Codes
          </div>
          <div class="text-subtitle-1 text-center font-weight-light">
            Add DAS Codes to the system
          </div>
          <v-form
              style="border-radius: 15px;"
          >
            <v-container
                class="py-0"
                style="border-radius: 20px;"
            >
              <v-form
                  v-model="valid"
                  @submit.prevent="createcode"
              >
                <v-row>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="deductionCode"
                        label="Deduction Code"
                        class="purple-input"
                        autocomplete="off"
                        outlined
                        placeholder="8000"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-text-field
                        v-model="payeeCode"
                        label="Payee Code"
                        class="purple-input"
                        autocomplete="off"
                        outlined
                        placeholder="8001"
                        :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="12"
                  >
<!--                    Currency Selection-->
                    <v-select
                        v-model="currency"
                        :items="['ZiG', 'USD']"
                        label="Currency"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"
                    >

                    </v-select>
                  </v-col>
                  <v-col
                      cols="8"
                      md="8"
                  >
                    <v-select
                        v-model="organisation"
                        :items="all_organisations"
                        item-text="name"
                        item-value="id"
                        label="Organisation"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                    />
                  </v-col>
                  <v-col
                      cols="4"
                      md="4"
                  >
                    <v-btn
                        :loading="loading"
                        color="primary"
                        solo
                        class="mr-0 mt-2"
                        @click="AddOrganisation = true"
                    >
                     Add Organisation
                    </v-btn>
                  </v-col>
                  <v-col
                      cols="12"
                      class="text-center"
                  >
                    <v-btn
                        :loading="loading"
                        color="primary"
                        solo
                        class="mr-0"
                        :disabled="!valid"
                        @click="createcode"
                    >
                      Save
                    </v-btn>
                    &nbsp; &nbsp;
                    <v-btn
                        color="red"
                        solo
                        class="mr-0"
                        @click="add = false, show_users=true"
                    >
                      Back
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-form>
          <br><br><br>
          <v-dialog
              v-model="dialog"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                 Success
              </v-card-title>

              <v-card-text class="text-center">
                A new code has been added to the system
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="Orgdialog"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                Organisation Creation<br> Successful
              </v-card-title>

              <v-card-text>
                Organisation has been added to the list for selection
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="Orgdialog = false"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="error_message"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                User Creation Failed
              </v-card-title>

              <v-card-text>
                The user details provided already exist
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <br><br><br>
    </v-container>
    <!-- end of add agent -->
    <v-container v-if="show_users">
      <v-row>
        <v-col
            cols="12"
            md="12"
            style="background-color: whitesmoke"
        >
          <v-card-title class="pb-2">
            DAS Codes List
            <v-spacer />
            <!-- add user -->
            <div class="text-center pb-1">
              <template>
                <v-btn
                    class="mx-2"
                    fab
                    dark
                    small
                    color="primary"
                    @click="show_users = false, add = true"
                >
                  <v-icon color="light">
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <v-btn
                  class="mx-7"
                  color="primary"
                  dark
                  @click="createExcel()"
              >Download &nbsp;
                <v-icon color="light">
                  mdi-file-excel
                </v-icon>
              </v-btn>
            </div>
            <!-- end of add user -->
            <v-spacer />
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
                class="pb-2"
            />
          </v-card-title>
          <v-data-table
              :headers="headers"
              :items="das_codes"
              class="elevation-3"
              :search="search"
          >
            <template v-slot:item.date_created="{ item }">
              <span>{{ new Date(item.date_created).toLocaleString() }}</span>
            </template>
            <template v-slot:item.is_active="{ item }">
              <v-chip
                  v-if="item.is_active === true"
                  color="primary"
                  dark
              >
                ACTIVE
              </v-chip>
              <v-chip
                  v-if="item.is_active === false"
                  color="warning"
                  dark
              >
                INACTIVE
              </v-chip>
            </template>
            <template v-slot:item.edit="{ item }">
              <v-btn
                  disabled
                  fab
                  x-small
                  color="primary"
                  @click="GoToEdit(item)"
              >
                <v-icon size="small">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <template v-slot:item.makerChecker="{ item }">
              <v-switch
                  @change="UpdateOrganisation(item)"
                  v-model="item.makerChecker"
                  inset
                  :label="item.makerChecker === true ? 'ENABLED' : 'DISABLED'"
              ></v-switch>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <template>
      <v-row justify="center">
        <v-dialog
            v-model="reset_confirm"
            max-width="290"
            persistent
        >
          <v-card>
            <v-card-title class="text-h5">
              Password Reset Request
            </v-card-title>

            <v-card-text class="text-center">
              Are you sure you want to reset password for <b>{{ selected_id_number }}</b>
            </v-card-text>

            <v-card-actions>
              <v-btn
                  color="green darken-1"
                  text
                  @click="reset_confirm = false"
              >
                Cancel
              </v-btn>
              <v-spacer />

              <v-btn
                  color="green darken-1"
                  text
                  @click="Reset_Password()"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="reset_success"
            max-width="290"
            persistent
        >
          <v-card>
            <v-card-title class="text-h5">
              Password Reset Successful
            </v-card-title>

            <v-card-text class="text-center">
              The new password for {{ selected_id_number }} is <b>Password1*</b>
            </v-card-text>

            <v-card-actions>
              <v-spacer />

              <v-btn
                  color="green darken-1"
                  text
                  @click="clear"
              >
                Continue
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="Userdialog"
            persistent
            width="80%"
        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="Userdialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />
              <v-toolbar-title class="align-center">
                Update User Details
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="Userdialog = false"
                >
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="selected_phoneNumber"
                        label="Phone Number"
                        hint="user contact number"
                        disabled
                        outlined
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="4"
                  >
                    <v-text-field
                        v-model="selected_id_number"
                        label="User ID Number"
                        hint="user ID number"
                        disabled
                        outlined
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      sm="6"
                      md="8"
                  >
                    <v-text-field
                        v-model="selected_address"
                        label="User Email"
                        outlined
                        disabled
                        hint="User Email"
                    />
                  </v-col>

                  <v-col
                      cols="12"
                      sm="6"
                      md="2"
                  >
                    <v-switch
                        v-model="selected_active"
                        inset
                        label="Is Active"
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions
                class="justify-center"
            >
              <v-btn
                  :loading = "loading"
                  color="success"
                  @click="Updateuser"
              >
                Update
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog
            v-model="AddOrganisation"
            persistent
            width="30%"
        >
          <v-card>
            <v-toolbar
                dark
                color="primary"
            >
              <v-btn
                  icon
                  dark
                  @click="AddOrganisation = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-spacer />
              <v-toolbar-title class="align-center">
                Add Organisation Name
              </v-toolbar-title>
              <v-spacer />
              <v-toolbar-items>
                <v-btn
                    dark
                    text
                    @click="AddOrganisation = false"
                >
                  Close
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                      cols="12"
                      sm="12"
                      md="12"
                  >
                    <v-text-field
                        v-model="organisation_name"
                        label="Organisation Name"
                        hint="add a new organisation"
                        outlined
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions
                class="justify-center"
            >
              <v-btn
                  :loading = "loading"
                  color="primary"
                  @click="SaveOrganisation()"
              >
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div class="text-center">
          <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            />
          </v-overlay>
        </div>
      </v-row>
    </template>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'
import { API } from '../../../api'
import moment from "moment";
import xlsx from "xlsx";

export default {
  name: 'User',
  computed: {
    ...mapState(['base_url']),
  },
  data () {
    return {
      reset_confirm: false,
      reset_success: false,
      all_organisations:[],
      agencies:[],
      organisation_name:"",
      organisation:"",
      agency: "",
      all_branches:[],

      fbcmanager_state: this.$store.state.is_fbcmanager,
      Orgdialog: false,
      overlay: false,
      dialog: false,
      error_message: false,
      success_message : false,
      valid: false,
      das_codes: [],
      loading: false,
      AddOrganisation: false,
      Userdialog: false,
      payeeCode: null,
      deductionCode: null,
      user_id_number: null,
      address: null,
      headers: [
        {
          sortable: true,
          text: 'PayeeCode',
          value: 'payeeCode',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'DeductionCode',
          value: 'deductionCode',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'Currency',
          value: 'currency',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'Organisation',
          value: 'organization_name',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'MakerChecker',
          value: 'makerChecker',
          class: 'primary--text title',
        },
        {
          sortable: true,
          text: 'Created',
          value: 'date_created',
          class: 'primary--text title',
        },

        {
          sortable: false,
          text: 'Edit',
          value: 'edit',
          class: 'primary--text title',
        },
      ],
      users: [],
      add: false,
      add_branch:false,
      show_users:true,
      user_type: null,
      search: null,
      currency: null,

      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length <= 20 || 'Max 20 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
    }
  },
  created () {
    this.getDASCodes()
    this.getOrganisations()
  },
  methods: {

    async GoToEdit (val) {
      this.selected_id_number = val.id_number
      this.selected_id = val.id
      this.reset_confirm = true
      console.log(val)
    },

    async getOrganisations () {
      const JWTToken = this.$store.getters.accessToken
      await axios.get('/das/get-organisations/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.all_organisations = res.data
              console.log(this.all_organisations)
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async createcode() {
      this.overlay = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      // try{
      let JWTToken = this.$store.getters.accessToken;
      console.log(this.$store.getters.accessToken);
      const payload = {
          "payeeCode": this.payeeCode,
          "deductionCode": this.deductionCode,
          "currency": this.currency,
          "organization": this.organisation,
      };
      console.log("payload",payload);
      var config = {
        method: "post",
        url: "/das/add-payeeCodes/",
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: payload
      };

      axios(config)
          .then((response) => {
            if (response.status === 201) {
              this.overlay = false;
              this.dialog = true;
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              this.error_message = true;
            }
            else {
              this.error_message = true;
            }
          });

    },
    async SaveOrganisation() {
      this.AddOrganisation = false;
      this.overlay = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      // try{
      let JWTToken = this.$store.getters.accessToken;
      console.log(this.$store.getters.accessToken);
      const payload = {
        "name": this.organisation_name,
      };
      console.log(payload);
      const config = {
        method: "post",
        url: "/das/add-organisation/",
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: payload
      };

      axios(config)
          .then((response) => {
            if (response.status === 201) {
              console.log(response.data, 'im the organisation created')
              this.getOrganisations()
              this.overlay = false
              this.Orgdialog = true
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              this.overlay = false;
            }
            else {
              this.overlay = false;
            }
          });

    },
    async UpdateOrganisation(val) {
      this.loading = true
      const UserData = {
        organisationName: val.organization_name,
        maker_checker: val.makerChecker
      }
      await new Promise(resolve => setTimeout(resolve, 2000))
      const JWTToken = this.$store.getters.accessToken
      axios.request({
        url: '/das/update-organisation/',
        method: 'post',
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: UserData,
      }).then(res => {
        if (res.status === 201) {
          console.log(res.data)
          this.loading = false
          this.getDASCodes()
          this.getOrganisations()
        } else if (res.status === 202) {
          this.overlay = false
        } else {
          this.overlay = false
          window.clearTimeout(this.timeoutID)
          this.$store.commit('clearUserData')
          alert('Your Session has ended')
          // this.$router.push("/login");
          window.location.href = '/login'
        }
      })
    },
    async Reset_Password(){
      this.reset_confirm = false;
      this.overlay = true;
      await new Promise(resolve => setTimeout(resolve, 2000));
      // try{
      let JWTToken = this.$store.getters.accessToken;
      console.log(this.$store.getters.accessToken);
      const payload = {
        "id": this.selected_id_number,
      };
      console.log(payload);
      const config = {
        method: "post",
        url: "/das/auth/reset_password/",
        headers: {
          Authorization: `Bearer ${JWTToken}`,
          'Content-Type': 'application/json',
        },
        data: payload
      };

      axios(config)
          .then((response) => {
            if (response.status === 200) {
              this.created_user_id = response.data.data.user_id
              this.overlay = false
              this.reset_success = true
            }
          })
          .catch((error) => {
            if (error.status === 400) {
              this.overlay = false;
            }
            else {
              this.overlay = false;
            }
          });

    },
    async createExcel() {
      const filename = String('AllCodes - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.das_codes
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'dascodes')
      xlsx.writeFile(wb, filename)
    },
    async getDASCodes () {
      const JWTToken = this.$store.getters.accessToken
      await axios.get('/das/payeeCodes/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.das_codes = res.data
              console.log(res.data)
              console.log("Users here  they are")
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
    },
    async clear() {
      await this.getDASCodes();
      this.email = "";
      this.selected_id_number ="";
      this.reset_confirm = false;
      this.currency = "";
      this.reset_success = false;
      this.deductionCode = "";
      this.payeeCode = "";
      this.organisation = "";
      this.username = "";
      this.institution = "";
      this.id_number = "";
      this.mobile_number = "";
      this.is_manager = false;
      this.overlay = false;
      this.dialog = false;
      this.error_message = false;
      this.success_message = false;
      this.add = false;
      this.show_users = true;
    }
  },
}
</script>
