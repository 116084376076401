<template>

        <!-- Outer Row -->
        <div class="back" >
            <slot></slot>
        </div>


</template>

<script>
    export default {
        name: "auth"
    }
</script>

<style scoped>
.back{
  position: relative;
  height: 100vh;
  margin: 0;
  background-color: #c5167f !important;
  background: -webkit-linear-gradient(-135deg, #e03078, #290974);
  background: -o-linear-gradient(-135deg, #e03078, #290974);
  background: -moz-linear-gradient(-135deg, #e03078, #290974);
  background: linear-gradient(-135deg, #e03078,#290974);
}
</style>