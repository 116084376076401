import Vue from 'vue'
import Router from 'vue-router'
import store from './store'
import Login from './views/Login'
import Invoice from './views/Invoice'
import Reset from './views/reset_password'
import Dashboard from '../src/views/dashboard/Dashboard'
import DashboardIndex from '../src/views/dashboard/Index'

import Booking from "@/views/dashboard/pages/Booking";
import IBooking from "@/views/dashboard/pages/Ibooking";
import AllBookings from "@/views/dashboard/pages/AllBookings";
import Batch from "@/views/dashboard/pages/Batch";
import AllBatch from "@/views/dashboard/pages/AllBatch";
import Records from "@/views/dashboard/pages/Records";
import User from "@/views/dashboard/pages/UserProfile";
import Branch from "@/views/dashboard/pages/Branch";
import Agency from "@/views/dashboard/pages/Agency";
import UploadMonthly from "@/views/dashboard/pages/UploadMonthly";
import Statement from "@/views/dashboard/pages/Statement";
import BSSDashboard from "@/views/dashboard/BSSDashboard";
import BSSRecords from "@/views/dashboard/pages/BSSRecords";
import AddUser from "@/views/dashboard/pages/AddUser";
import BSSOrgCount from "@/views/dashboard/pages/BSSOrgCount";
import BSSAddCodes from "@/views/dashboard/pages/BSSAddCodes";
import Billing from "@/views/dashboard/pages/Billing";
import BSSInvoices from "@/views/dashboard/pages/BSSInvoices";
import GenerateInvoice from "@/views/dashboard/pages/GenerateInvoice";
// import { checkTokenStatus } from './services/auth.service'

Vue.use(Router)
const routes = [
  {
    path: '/',
    component: Login,
  },
  {
    path: '/invoice/:id',
    component: Invoice,
    beforeEnter: (to, from, next) => {
      if(store.state.isAuthenticated === false) {
        next("/login");
      } else {
        next();
      }
    }
  },
  {
    path: '/login',
    component: Login,
  },
  {
    path: '/reset_password',
    component: Reset,
  },
  {
    path: '/user',
    component: DashboardIndex,
    children: [
      // {
      //   path: 'dashboard',
      //   component: Dashboard,
      //   beforeEnter: async (to, from, next) => {
      //     if (await checkTokenStatus() === 401) {
      //       next('/login')
      //     } else {
      //       next()
      //     }
      //   },
      // },
      {
        path: 'dashboard',
        component: Dashboard,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'dashboard-bss',
        component: BSSDashboard,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false || store.state.is_bss === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'add-user',
        component: AddUser,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false || store.state.is_bss === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'bss-records',
        component: BSSRecords,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false || store.state.is_bss === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'add-codes',
        component: BSSAddCodes,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false || store.state.is_bss === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'bss-orgcount',
        component: BSSOrgCount,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false || store.state.is_bss === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'bss-invoices',
        component: BSSInvoices,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false && store.state.is_bss === false || store.state.is_accounts === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'finance-generate-invoice',
        component: GenerateInvoice,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false && store.state.is_bss === false || store.state.is_accounts === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'booking',
        component: Booking,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'ibooking',
        component: IBooking,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'all-bookings',
        component: AllBookings,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false && store.state.is_ssb === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'upload-statement',
        component: UploadMonthly,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false && store.state.is_ssb === true) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'all-batch',
        component: AllBatch,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false && store.state.is_ssb === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'batch',
        component: Batch,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'statement',
        component: Statement,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'records',
        component: Records,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'billing',
        component: Billing,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
      {
        path: 'list',
        component: User,
        beforeEnter: (to, from, next) => {
          if(store.state.isAuthenticated === false) {
            next("/login");
          } else {
            next();
          }
        }
      },
    ],

  },
  {
    path: '/branch',
    component: DashboardIndex,
    children:[
     {
      path: 'list',
      component: Branch,
     },
      {
        path: 'agency',
        component: Agency,
      }
    ]
  },
]
const router = new Router(
  {
    routes,
    mode: 'history',
  },
)

export default router

