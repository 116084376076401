<template>
  <div>
    <v-container
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Generate Invoices
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-container
              class="rounded elevation-0"
          >
            <v-form
                v-model="valid"
                class="rounded elevation-2 pa-3"
                @submit.prevent="GenerateInvoice"
            >
              <v-row class="mt-2">
                <v-col
                    cols="3"
                    md="2"
                >
                </v-col>
                <v-col
                    cols="10"
                    md="8"
                >
                  <v-select
                      v-model="month"
                      :items="months"
                      item-text="month"
                      item-value="month"
                      label="Select Month to generate"
                      outlined
                      :rules="[rules.required]"
                      style="text-transform: uppercase"
                  />
                </v-col>
                <v-col
                    cols="12"
                    class="text-center mb-1"
                >
                  <v-btn
                      color="primary"
                      :disabled="!valid"
                      @click="GenerateInvoice"
                  >
                    Generate
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <v-row v-if="false" class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col cols="10" md="10" style="background-color: whitesmoke; justify-content: center">
              <v-card-title>
                Preview
                <v-spacer></v-spacer>
                <br>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="uploadData"
              >
                <template v-slot:item.startDate="{ item }">
                  <span v-if="item.startDate === 'Invalid date'" style="color: #e91e63">Invalid Date</span>
                  <span v-else >{{ item.startDate }}</span>
                </template>
                <template v-slot:item.endDate="{ item }">
                  <span v-if="item.endDate === 'Invalid date'" style="color: #e91e63">Invalid Date</span>
                  <span v-else >{{ item.endDate }}</span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog
          v-model="successUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Generate Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Generation Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="goTo('/user/bss-invoices')"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              Records already exist
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="goTo()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload2"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              Invalid File Type
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload3"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title  v-if="true">
              <h3 style="align-content: center">Data Error</h3>

            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                class="pt-6"
                style="color: #800808;"
            >
              {{ ECRefError }}
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear()"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>

    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import {getUserIP} from "@/services/userIp";
export default {
  name: 'Booking',
  data () {
    return {
      months: [],
      isMakerChecker: false,
      payeeCodes:[],
      overlay: false,
      files: [],
      valid:false,
      invalidity: false,
      successUpload:false,
      errorUpload: false,
      errorUpload2: false,
      errorUpload3: false,
      dasCurrency: '',
      ECRefError: '',
      deductionCode: '',
      month: '',
      uploadData:[],
      headers: [
          { text: 'Reference', value: 'reference' },
          { text: 'IdNumber', value: 'idNumber' },
          { text: 'EcNumber', value: 'ecNumber' },
          { text: 'Type', value: 'type' },
          { text: 'StartDate', value: 'startDate' },
          { text: 'EndDate', value: 'endDate' },
          { text: 'Amount(cents)', value: 'amount' },
        ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Generate Invoice',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  computed: {
    ...mapState(['maker_checker']),
  },
  created () {
    this.getMonths()
  },
  methods: {
    async getMonths() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/billing/get-statementmonths/',{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.months = res.data
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    async GenerateInvoice(){
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      const data = {
        month: this.month,
      }
      axios.post('/das/billing/generate-invoices/', data,{ headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.overlay = false
              this.successUpload = true
            } else if (res.status === 404) {
              this.overlay = false
              this.errorUpload = true
            } else {
              this.overlay = false
              this.errorUpload = true
            }
          })
      this.clear()
    },
    goTo (link) {
      this.$router.push(link)
    },
    clear () {

      this.overlay = false,
      this.valid = false,
      this.invalidity = false,
      this.successUpload = false,
      this.errorUpload = false,
      this.errorUpload2 = false,
          this.errorUpload3 = false,
      this.dasCurrency = '',
      this.deductionCode = '',
      this.payeeCode = '',
          this.files = [],
      this.uploadData = []
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
