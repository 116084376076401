var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(true)?_c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card-title',[_c('div',[_c('v-breadcrumbs',{attrs:{"items":_vm.links}})],1),_c('v-spacer'),_c('div',{staticClass:"text-h3 text-center font-weight-medium"},[_vm._v(" Uploaded batch records ")]),_c('v-spacer'),_c('v-spacer')],1)],1)],1),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticStyle:{"justify-content":"center"},attrs:{"cols":"1","md":"1"}}),_c('v-col',{staticStyle:{"background-color":"whitesmoke"},attrs:{"cols":"10","md":"10"}},[_c('v-card-title',[_vm._v(" Batch Records "),_c('v-spacer'),[_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.createExcel()}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-download ")])],1)],_c('v-spacer'),_c('br'),_c('br'),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],2),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.batch,"search":_vm.search,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([{key:"item.owner",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v("Admin Account")])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.name === null )?_c('span',[_vm._v("MIC2210010022")]):_c('span',[_vm._v(_vm._s(item.name))])]}},{key:"item.applicationDate",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(new Date(item.applicationDate).toLocaleString()))])]}},{key:"item.ssb_upload",fn:function(ref){
var item = ref.item;
return [(item.batch_status === 'Processing' || item.batch_status === 'Pending')?_c('span',[_vm._v("--- --- ---")]):_c('span',[_vm._v(_vm._s(new Date(item.ssb_upload).toLocaleString()))])]}},{key:"item.ssb_download",fn:function(ref){
var item = ref.item;
return [(item.batch_status === 'Pending')?_c('span',[_vm._v("--- --- ---")]):_c('span',[_vm._v(_vm._s(new Date(item.ssb_download).toLocaleString()))])]}},{key:"item.batch_status",fn:function(ref){
var item = ref.item;
return [(item.batch_status === 'Pending')?_c('v-chip',{attrs:{"color":"warning","dark":""}},[_vm._v(" PENDING ")]):_vm._e(),(item.batch_status === 'Processing')?_c('v-chip',{attrs:{"color":"purple","dark":""}},[_vm._v(" PROCESSING ")]):_vm._e(),(item.batch_status === 'Mixed')?_c('v-chip',{attrs:{"color":"primary","dark":""}},[_vm._v(" MIXED ")]):_vm._e(),(item.batch_status === 'Success')?_c('v-chip',{attrs:{"color":"success","dark":""}},[_vm._v(" SUCCESS ")]):_vm._e(),(item.batch_status === 'Failed')?_c('v-chip',{attrs:{"color":"danger","dark":""}},[_vm._v(" FAILED ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.batch_status === 'Success' || item.batch_status === 'Failed' || item.batch_status === 'Mixed')?_c('v-btn',{attrs:{"color":"primary","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Excel ")],1):_vm._e(),_vm._v("   "),(item.batch_status === 'Success' || item.batch_status === 'Failed' || item.batch_status === 'Mixed')?_c('v-btn',{attrs:{"color":"success","dark":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-download ")]),_vm._v(" Source ")],1):_vm._e()]}}],null,false,624235609)})],1)],1),_c('br'),_c('br'),_c('br')],1)],1)],1):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }