<template>
  <div>
<!-- add branch -->
  <v-container
      v-if="add_branch"
      id="user-profile"
      fluid
      tag="section"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          md="9"
          style="background-color: whitesmoke"
        >
          <div class="text-h3 text-center font-weight-medium">
            Add Agency
          </div>
          <div class="text-subtitle-1 text-center font-weight-light">
            Complete agency information
          </div>
          <v-form>
            <v-container
              class="py-0"
              style="border-radius: 20px;"
            >
              <v-form
                v-model="valid"
                @submit.prevent="createAgency"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="agency_name"
                      label="Agency Name"
                      class="purple-input"
                      autocomplete="off"
                      outlined
                      placeholder="Company Name"
                      :rules="[rules.required]"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="agency_email"
                      label="Agency email"
                      class="purple-input"
                      autocomplete="off"
                      outlined
                      placeholder="john.doe@example.com"
                      :rules="[rules.required, rules.email]"
                    />
                  </v-col>
                  <v-col
                      cols="12"
                      md="6"
                  >
                    <v-select
                        v-model="industry"
                        :items="industries"
                        item-text="name"
                        item-value="value"
                        label="Industry"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                    />
                  </v-col>
                  <v-col
                      cols="10"
                      md="4"
                  >
                    <v-select
                        v-model="category"
                        :items="categories"
                        item-text="category"
                        item-value="id"
                        label="Agency Category"
                        outlined
                        :rules="[rules.required]"
                        style="text-transform: uppercase"

                    />
                  </v-col>
                  <v-col
                      cols="2"
                      md="2"
                  >
                    <v-btn
                        class="mx-2"
                        fab
                        dark
                        color="primary"
                        @click="PricingDialog = true"
                    >
                      <v-icon dark>
                        mdi-plus
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    class="text-center"
                  >
                    <v-btn
                      :loading="loading"
                      color="primary"
                      solo
                      class="mr-0"
                      :disabled="!valid"
                      @click="createAgency"
                    >
                      Save
                    </v-btn>
                    &nbsp;&nbsp;
                    <v-btn
                        color="red"
                        solo
                        class="mr-0"
                        @click="add_branch = false, show_branches=true"
                    >
                      Back
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-form>
          <br><br><br>

          <v-dialog
            v-model="error_message"
            max-width="290"
            persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                Agency Creation Failed
              </v-card-title>

              <v-card-text>
                The Agency details provided already exist
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                  color="green darken-1"
                  text
                  @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog
              v-model="branch_create_message"
              max-width="290"
              persistent
          >
            <v-card>
              <v-card-title class="text-h5">
                SUCCESS
              </v-card-title>

              <v-card-text>
                The Agency has been created successfully
              </v-card-text>

              <v-card-actions>
                <v-spacer />

                <v-btn
                    color="green darken-1"
                    text
                    @click="clear"
                >
                  Continue
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <br><br><br>
  </v-container>
<!-- end of add branch -->
    <v-container v-if="show_branches">
      <v-row>
        <v-col
          cols="12"
          md="12"
          style="background-color: whitesmoke"
        >
          <v-card-title>
            Agency List
            <v-spacer />
            <v-spacer />
            <!-- add branch -->
            <template>
              <div class="text-center ml-10">
                <v-btn
                  color="primary"
                  dark
                  @click="add_branch = true, show_branches = false"
                >
                  Add new agency
                </v-btn>
              </div>
            </template>
            <!-- end of add branch -->
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="agencies"
            class="elevation-3"
            :search="search"
          >
            <template v-slot:item.is_active="{ item }">
              <v-chip
                v-if="item.is_active === true"
                color="primary"
                dark
              >
                ACTIVE
              </v-chip>
              <v-chip
                v-if="item.is_active === false"
                color="warning"
                dark
              >
                INACTIVE
              </v-chip>
            </template>

            <template v-slot:item.type="{ item }">
              <v-chip
                v-if="item.is_agent === true"
                color="primary"
                dark
              >
                CALLS CENTER
              </v-chip>
              <v-chip
                v-else-if="item.is_driver === true"
                color="primary"
                dark
              >
                DRIVER
              </v-chip>
              <v-chip
                v-else-if="item.is_staff === true"
                color="primary"
                dark
              >
                LEAD AGENT
              </v-chip>
              <v-chip
                v-else
                color="secondary"
                dark
              >
                UNASSIGNED
              </v-chip>
            </template>


            <template v-slot:item.view="{ item }">
              <v-btn
                fab
                small
                color="primary"
                @click="GoToEdit(item)"
              >
                <v-icon>
                  mdi-eye
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="PricingDialog"
        persistent
        width="40%"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="PricingDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="align-center">
            Add new Commission Pricing
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
                dark
                text
                @click="agencyPricing"
            >
              Create
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-select
                    v-model="category_class"
                    :items="categories_classes"
                    item-text="name"
                    item-value="value"
                    label="Category"
                    outlined
                    :rules="[rules.required]"
                    style="text-transform: uppercase"

                />
              </v-col>
              <v-col
                  cols="12"
                  sm="12"
                  md="6"
              >
                <v-text-field
                    v-model="price"
                    label="Commission % (eg 0.3)"
                    hint="Agency Commission %"
                    type="number"
                    outlined
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
            class="justify-center"
        >
          <v-btn
              :loading = "loading"
              color="primary"
              @click="agencyPricing"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
import { API } from '../../../api'

  export default {
    name: 'Agency',
    computed: {
      ...mapState(['base_url']),
    },
    data () {
      return {
        category_class: "",
        price: "",
        PricingDialog: false,
        agency_email:'',
        industry:'',
        category:'',
        branch_create_message:false,
        all_branches:[],
        agencies:[],
        categories:[],
        agency_name:"",
        agency_address:"",
        email: "",
        username: "",
        institution: "",
        id_number: "",
        mobile_number: "",
        is_manager: false,
        overlay: false,
        dialog: false,
        error_message: false,
        success_message : false,
        valid: false,
        items: ['Is Manager', 'Not a Manager'],
        industries: [{ name: 'Consulting', value: 'Consulting' },
          { name: 'Electronic Payments', value: 'Electronic Payments' },
          { name: 'Banking', value: 'Banking' },
          { name: 'Funeral Services', value: 'Funeral Services' },
          { name: 'Micro Finance', value: 'Micro Finance' },
          { name: 'Tertiary', value: 'Tertiary' },
          { name: 'Clothing', value: 'Clothing' }],
        categories_classes: [{ name: 'CategoryA', value: 'CategoryA' },
          { name: 'CategoryB', value: 'CategoryB' },
          { name: 'CategoryC', value: 'CategoryC' },
          { name: 'CategoryD', value: 'CategoryD' },
          { name: 'CategoryE', value: 'CategoryE' },
          { name: 'CategoryF', value: 'CategoryF' },
          { name: 'CategoryG', value: 'CategoryG' }],
        loading: false,
        Userdialog: false,
        first_name: null,
        last_name: null,
        user_id_number: null,
        address: null,
        headers: [
          {
            sortable: true,
            text: 'AGENCY NAME',
            value: 'name',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'AGENCY EMAIL',
            value: 'email',
            class: 'primary--text title',
          },

          {
            sortable: false,
            text: 'VIEW',
            value: 'view',
            class: 'primary--text title',
          },
        ],
        users: [],
        types: [{ name: 'Call Center', value: 1 },
                { name: 'Lead Agent', value: 2 },
                { name: 'Driver', value: 3 }],
        add: false,
        add_branch:false,
        show_branches:true,
        user_type: null,
        search: null,
        is_staff: false,
        is_agent: false,
        is_driver: false,
        selected_active: false,
        selected_is_staff: false,
        selected_is_agent: false,
        selected_is_driver: false,
        selected_is_supervisor: false,
        selected_firstname: null,
        selected_lastname: null,
        selected_id_number: null,
        selected_address: null,
        selected_phoneNumber: null,
        rules: {
          required: value => !!value || 'Required.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Invalid e-mail.'
          },
        },
      }
    },
    created () {
      this.getCategories()
      this.getAgency()
    },
    methods: {
      async GoToEdit (val) {
        this.selected_firstname = val.firstname
        this.selected_lastname = val.lastname
        this.selected_id_number = val.id_number
        this.selected_address = val.address
        this.selected_phoneNumber = val.mobile_number
        this.selected_active = val.is_active
        this.selected_is_staff = val.is_staff
        this.selected_is_agent = val.is_agent
        this.selected_is_driver = val.is_driver
        this.supervisor_id = val.supervisor_id
        this.selected_is_supervisor = val.is_supervisor
        this.selected_id = val.id
        this.Userdialog = true
        console.log(val)
      },

      async createAgency(){
        let JWTToken = this.$store.getters.accessToken;
        console.log(this.$store.getters.accessToken);

        const payload = {
          "name": this.agency_name,
          "email": this.agency_email,
          "industry": this.industry,
          "agent_category": this.category
        };

        var config = {
          method: "post",
          url: this.$base_url+"/api/auth/institution-view/",
          data: payload,
          headers: { Authorization: `Bearer ${JWTToken}` }

        };

        axios(config)
        .then((response) => {
              if (response.status === 200) {
                console.log(response.data, 'new branch created')
                this.overlay = false;
                this.getAgency()
                this.branch_create_message = true;
                this.success_message = true;

                // alert('User created successfully and verification email has been to to the user.');
                // location.replace("/user_creation");
              }
            })
            .catch((error) => {
              console.log(error)
              if (error.status === 400) {
                this.error_message = true;

              }
              else {
                this.error_message = true;
              }
            });


      },
      async getCategories () {
        const JWTToken = this.$store.getters.accessToken
        await axios.get(this.$base_url + '/api/auth/agency-pricing/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.categories = res.data
              console.log(this.categories)
            } else if (res.status === 202) {
              console.log(res.status)
            } else {
              this.overlay = false
              window.clearTimeout(this.timeoutID)
              this.$store.commit('clearUserData')
              alert('Your Session has ended')
              // this.$router.push("/login");
              window.location.href = '/login'
            }
          })
      },
      async getAgency () {
        const JWTToken = this.$store.getters.accessToken
        await axios.get(this.$base_url + '/api/auth/institution-view/', { headers: { Authorization: `Bearer ${JWTToken}` } })
            .then(res => {
              if (res.status === 200) {
                this.agencies = res.data
                console.log(this.agencies)
              } else if (res.status === 202) {
                console.log(res.status)
              } else {
                this.overlay = false
                window.clearTimeout(this.timeoutID)
                this.$store.commit('clearUserData')
                alert('Your Session has ended')
                // this.$router.push("/login");
                window.location.href = '/login'
              }
            })
      },
      async agencyPricing(){
        let JWTToken = this.$store.getters.accessToken;
        console.log(this.$store.getters.accessToken);

        const payload = {
          "category": this.category_class,
          "price": this.price
        };
        console.log(payload)
        var config = {
          method: "post",
          url: this.$base_url+"/api/auth/agency-pricing/",
          data: payload,
          headers: { Authorization: `Bearer ${JWTToken}` }

        };

        axios(config)
            .then((response) => {
              if (response.status === 200) {
                console.log(response.data, 'new branch created')
                this.overlay = false;
                this.success_message = true;
                this.getCategories()
                this.PricingDialog = false
                // alert('User created successfully and verification email has been to to the user.');
                // location.replace("/user_creation");
              }
            })
            .catch((error) => {
              console.log(error)
              if (error.status === 400) {
                this.error_message = true;

              }
              else {
                this.error_message = true;
              }
            });


      },
      async clear() {
        this.getCategories();
        this.email = "";
        this.PricingDialog = false;
        this.username = "";
        this.institution = "";
        this.id_number = "";
        this.mobile_number = "";
        this.is_manager = false;
        this.overlay = false;
        this.dialog = false;
        this.error_message = false;
        this.success_message = false;
        this.add = false;
        this.show_branches = true;
        this.branch_create_message=false;
        this.add_branch = false;
      }
    },
  }
</script>
