<template>
  <div>
    <v-container
        id="dashboard"
        fluid
        tag="section"
    >
      <div>
        <v-row>
          <v-col
              v-if="status_stats.pendingInvoices > 0"
              cols="12"
              sm="12"
              lg="12"
          >
            <template>
              <div>
                <v-alert
                    v-model="alert"
                    color="danger"
                    border="left"
                    elevation="2"
                    colored-border
                    class="mb-4 mt-4"
                    icon="mdi-alert-circle-outline"
                >
                  <h4>You've got <strong>{{ String(status_stats.pendingInvoices) }}</strong> invoice(s) pending payment!</h4>
                  <p style="color: #800808; font-weight: normal;"> To avoid service disruption, please make your payment
                    and upload the proof of payment under the <a @click="GoTo('invoice')"><b style="text-decoration: underline">INVOICES</b></a>  section.</p>
                </v-alert>
              </div>
            </template>
          </v-col>
          <input type="file" ref="myFile" @change="SSBUploadFiles" hidden ><br/>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-check-circle-outline"
                title="Successful"
                :value="String(status_stats.success)"
                sub-icon="mdi-check-all"
                sub-icon-color="green"
                sub-text="Bookings Successful"
            />
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-progress-clock"
                title="Processing"
                :value="String(status_stats.processing)"
                sub-icon="mdi-alert"
                sub-icon-color="yellow"
                sub-text="Bookings currently in processing"
            />
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
              color="primary"
              icon="mdi-download-off-outline"
              title="Pending"
              :value="String(status_stats.pending)"
              sub-icon="mdi-eye-off"
              sub-icon-color="purple"
              sub-text="Booking Pending Processing"
            />
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
              color="primary"
              icon="mdi-close-circle-outline"
              title="Failed"
              :value="String(status_stats.failed)"
              sub-icon="mdi-close"
              sub-icon-color="red"
              sub-text="Failed bookings"
            />
          </v-col>
          <v-col
              v-if="SSBstate"
              cols="12"
              sm="6"
              lg="6"
          >
            <base-material-stats-card
              color="primary"
              icon="mdi-download-off-outline"
              title="Pending"
              :value="String(status_stats.pending)"
              sub-icon="mdi-alert"
              sub-icon-color="yellow"
              sub-text="Bookings Pending Processing"
            />
          </v-col>
          <v-col
              v-if="SSBstate"
              cols="12"
              sm="6"
              lg="6"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-eye"
                title="In Processing"
                :value="String(status_stats.processing)"
                sub-icon="mdi-eye"
                sub-icon-color="purple"
                sub-text="Bookings Being Processed"
            />
          </v-col>
          <v-col
              v-if="false"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-account-check-outline"
                title="Completed"
                :value="String(SSBProcessed)"
                sub-icon="mdi-check-all"
                sub-icon-color="green"
                sub-text="Bookings Processed"
            />
          </v-col>
          <v-col
              v-if="false"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-check"
                title="Successful"
                :value="String(status_stats.success)"
                sub-icon="mdi-check"
                sub-icon-color="blue"
                sub-text="Bookings Successful"
            />
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Send Files
                  </div>
                  <v-list-item-title class="text-h5 mb-1" color="primary">
                    File Upload
                  </v-list-item-title>
                  <v-list-item-subtitle>Attach and send files</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/upload.svg"
                      width="7em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    @click="GoTo('booking')"
                    color="dark"
                    outlined
                    rounded
                >
                  Attach
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="SSBstate"
              cols="12"
              sm="6"
              lg="4"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Response Files
                  </div>
                  <v-list-item-title class="text-h5 mb-1" color="primary">
                    File Upload
                  </v-list-item-title>
                  <v-list-item-subtitle>Attach and send files</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/upload.svg"
                      width="7em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    @click="dasUpload = true"
                    color="dark"
                    outlined
                    rounded
                >
                  Attach
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="SSBstate"
              cols="12"
              sm="6"
              lg="4"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    DAS File Download
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    File Download
                  </v-list-item-title>
                  <v-list-item-subtitle>Press download button</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/download.svg"
                      width="5em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    color="dark"
                    outlined
                    :loading="EcocashWait > 1"
                    rounded
                    :disabled = "status_stats.pending < 1"
                    @click="DownloadSSB"
                >
                  Download
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="SSBstate"
              cols="12"
              sm="6"
              lg="4"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Upload Statement
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    File Upload
                  </v-list-item-title>
                  <v-list-item-subtitle>Attach and Upload statements</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/download.svg"
                      width="5em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    color="dark"
                    outlined
                    rounded
                    @click="GoTo('statement')"
                >
                  Go To
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="false"
              cols="12"
              sm="6"
              lg="3"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    View Batch
                  </div>
                  <v-list-item-title class="text-h5 mb-1">
                    Batch Status
                  </v-list-item-title>
                  <v-list-item-subtitle>Press download button</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/download.svg"
                      width="5em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    color="dark"
                    outlined
                    rounded
                    @click="GoTo('SSBBatch')"
                >
                  View
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    View Batch
                  </div>
                  <v-list-item-title class="text-h5 mb-1" color="primary">
                    View Status
                  </v-list-item-title>
                  <v-list-item-subtitle>View batch records status</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/upload.svg"
                      width="7em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    @click="GoTo('batch')"
                    color="dark"
                    outlined
                    rounded
                >
                  View
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    Individual bookings
                  </div>
                  <v-list-item-title class="text-h5 mb-1" color="primary">
                    View Bookings
                  </v-list-item-title>
                  <v-list-item-subtitle>View individual bookings</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/upload.svg"
                      width="7em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    @click="GoTo('records')"
                    color="dark"
                    outlined
                    rounded
                >
                  View
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <v-card
                class="mx-auto elevation-2 pa-3"
                max-width="344"
                outlined
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="text-overline mb-4">
                    monthly download
                  </div>
                  <v-list-item-title class="text-h5 mb-1" color="primary">
                    File download
                  </v-list-item-title>
                  <v-list-item-subtitle>Press download to get report</v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-avatar
                    tile
                    size="50"
                    color="white"
                >
                  <v-img
                      src="@/assets/upload.svg"
                      width="7em"
                  />
                </v-list-item-avatar>
              </v-list-item>

              <v-card-actions>
                <v-btn
                    @click="GoTo()"
                    color="dark"
                    outlined
                    rounded
                >
                  Download
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col v-if="false"  cols="12" md="12" style="background-color: whitesmoke">

            <v-card-title>
              Records

              <template>
              </template>
              <v-spacer></v-spacer>
              <br>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="uploadedData"
                :search="search"

            >
            </v-data-table>
          </v-col>
        </v-row>
      </div>
      <v-dialog
          v-model="dasUpload"
          persistent
          width="40%"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-btn
                icon
                dark
                @click="clear()"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title class="text-center">
              Upload Responses
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2>
              Select Files to upload
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >

                  <v-file-input
                      @change="SSBUploadFiles"
                      v-model="files"
                      placeholder="Upload your documents"
                      label="File input"
                      multiple
                      prepend-icon="mdi-paperclip"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip
                          small
                          label
                          color="primary"
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="mydata"
                  >
                    Upload
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="successUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Upload Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              References do not exist
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="successDload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Download Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Download Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import moment from 'moment'
  import xlsx from 'xlsx'
  const dataToJson = require("data-to-json");

  export default {
    name: 'Dashboard',
    data () {
      return {
        DownloadUrl: 'http://127.0.0.1:8000/ssbdownload/',
        processing: '0',
        files:[],
        failed: '0',
        success: '0',
        pending: '0',
        Myfiles: '',
        ssbData: [],
        SSBProcessed: '0',
        SSBProcessing: '0',
        SSBPending: '0',
        SSBSuccess: '0',
        dasUpload: false,
        uploadedData: [],
        errorUpload:false,
        successUpload:false,
        successDload: false,
        errorDload: false,
        EcocashWait: 0,
        status_stats: {"success": 0, "failed": 0, "pending": 0, "processing": 0},
        date: new Date().toISOString().substr(0, 7),
        modal: false,
        year: new Date().getFullYear(),
        isloading: false,
        search: '',
        overlay: false,
        SSBstate: false,
        headers:[]
      };
    },
    watch: {
      EcocashWait: {
        handler (value) {
          if (value > 0) {
            setTimeout(() => {
              this.EcocashWait--
            }, 1000)
          }
        },
        immediate: true, // This ensures the watcher is triggered upon creation
      },
    },
    created() {
      this.SSBstate = this.$store.getters.is_ssb
      this.statusStats()
    },
    computed: {
      ...mapState(['is_auditor', 'access_token', 'base_url', 'is_fbcmanager','loggedInUser']),
    },
    methods: {
      statusStats(){
        this.overlay = true
        const JWTToken = this.$store.getters.accessToken
        axios.get('/das/get-statuscount/', { headers: { Authorization: `Bearer ${JWTToken}` } })
            .then(response => {
              if (response.status === 200) {
                this.overlay = false;
                this.status_stats = response.data
                console.log(this.status_stats)
              }
            })
            .catch((error) => {
              if (error.response.status === 401) {
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              } else if (error.response.status === 400) {
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              } else if (error.response.status === 500) {
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              }
              else{
                this.overlay = false
                this.info_type = 'error'
                this.info_desc = error
                this.error_message = true
              }
            })
      },
      async getBookings() {
        const JWTToken = this.$store.getters.accessToken
        axios.get('/das/all-bookings/?organisation=' + this.$store.getters.institution)
            .then(res => {
              if (res.status === 200) {
                this.records = res.data
                this.processing = this.records.filter((item) => {return (item.status === 'Processing')}).length
                this.success = this.records.filter((item) => {return (item.status === 'Success')}).length
                this.pending = this.records.filter((item) => {return (item.status === 'Pending')}).length
                this.failed = this.records.filter((item) => {return (item.status === 'Failed')}).length
                console.log(this.records)
              } else if (res.status === 202) {
                console.log('202')
              } else {
                console.log('error')
              }
            })
      },
      async getSSBBookings() {
        const JWTToken = this.$store.getters.accessToken
        axios.get('/das/all-bookings/?organisation=SSB')
            .then(res => {
              if (res.status === 200) {
                this.SSBrecords = res.data
                this.SSBProcessing = this.SSBrecords.filter((item) => {return (item.status === 'Processing')}).length
                this.SSBProcessed = this.SSBrecords.filter((item) => {return (item.status === 'Success' || item.status === 'Failed')}).length
                // this.SSBPending = this.SSBrecords.filter((item) => {return (item.status === 'Pending')}).length
                this.SSBSuccess = this.SSBrecords.filter((item) => {return (item.status === 'Success')}).length
                let PendingDownloads =  this.SSBrecords.filter((item) => {return (item.status === 'Pending')})
                let unique =  [...new Map(PendingDownloads.map((x) => [x['payeeCode'], x])).values()]
                this.SSBPending = unique.length
              } else if (res.status === 202) {
                console.log('202')
              } else {
                console.log('error')
              }
            })
      },
      async mydata() {
        this.overlay = true;
        console.log(this.ssbData)
        axios.post( '/das/ssbrawfiles/', this.ssbData).then(function(){console.log('SUCCESS!!');
        }).catch(function(){console.log('FAILURE!!');});

        // this.uploadedData.forEach((node) => delete node.Amount);
        // this.uploadedData.forEach((node) => delete node.FromDate);
        // this.uploadedData.forEach((node) => delete node.Payee);
        // this.uploadedData.forEach((node) => delete node.adCode);
        // this.uploadedData.forEach((node) => delete node.ecNumber);
        // this.uploadedData.forEach((node) => delete node.idNumber);
        // this.uploadedData.forEach((node) => delete node.Tran);
        // this.uploadedData.forEach((node) => delete node.ToDate);
        // this.uploadedData.forEach((node) => delete node.Ref);
        // this.uploadedData.forEach((node) => delete node.Names);
        // this.uploadedData.forEach((node) => delete node.AD);
        // this.uploadedData.forEach((node) => delete node.Branch);
        // this.uploadedData.shift()
        console.log("new json",this.uploadedData);

        this.dasUpload = false
        await new Promise((resolve) => setTimeout(resolve, 3000));
        await axios
            .post("/das/ssbupdate/", this.uploadedData)
            .then((response) => {
              console.log(response)
              this.overlay = true;
              this.dasUpload = false;
              if (response.status === 200) {
                console.log(response.data);
                this.overlay = false;
                this.dasUpload = false
                this.successUpload = true;
                // this.$router.push("/user/dashboard").catch(() => {})
              } else if (response.status === 404) {
                this.overlay = false;
                this.dasUpload = false
                this.successUpload = true;
              } else {
                this.overlay = false;
                this.successUpload = true;
                console.log(response)
              }
            })
            .catch((error) => {
              if (error.response.status === 400) {
                this.overlay = false;
                this.dasUpload = false
                this.successUpload = true;
              } else if (error.response.status === 404) {
                this.overlay = false;
                this.dasUpload = false
                this.successUpload = true;
              } else if (error.response.status === 500) {
                this.overlay = false;
                this.dasUpload = false
                this.successUpload = true;
              } else {
                this.overlay = false;
                this.dasUpload = false
                this.successUpload = true;
              }
            });
      },
      async SSBUploadFiles() {
        console.log(this.files.length)
        const dataInJSON = [];
        const that = this
        for (let i = 0; i < this.files.length; i++) {
          let file = this.files[i];
          const filename = file.name.split('.')[0]
          const uploadState = filename.slice(-3)
          console.log("State",uploadState)
          let status = '';
          // if (uploadState === 'rep'){
          //   status = 'Success'
          // }
          // if (uploadState === 'err'){
          //   status = 'Failed'
          // }
          let reader = new FileReader();
          reader.readAsText(file, "UTF-8");
          reader.onload = evt => {
            this.text = evt.target.result;
            let randomNum = Math.floor((Math.random() * 800) + 111)
            let suffix = randomNum.toString();
            let new_filename = filename + '' + suffix
            const SSBfile = {name: new_filename, data: this.text}
            that.ssbData = this.ssbData.concat(SSBfile)
            // this.text = this.text.replace('EC No', 'ecNumber');
            this.text = this.text.slice(this.text.indexOf("Amount") + 6);
            this.text = this.text.slice(this.text.indexOf("Bank Account") + 12);
            this.text = this.text.split('Total Records')[0];
            this.text = this.text.split('Total Errors')[0];
            console.log(this.text)
            // this.text = this.text.replace('EC No', 'ecNumber');
            // this.text = this.text.replace('AD Code', 'adCode');
            // this.text = this.text.replace('Id Number', 'idNumber');
            // this.text = this.text.replace('From Date', 'FromDate');
            // this.text = this.text.replace('To Date', 'ToDate');
            // this.text = this.text.replace('Bank Account', 'bank');
            // By lines
            const lines = this.text.split('\n');
            for (let line = 0; line < lines.length; line++) {
              if(uploadState === 'err'){
                let payeeCode = lines[line].substring(19, 24).trim();
                let ecNumber = lines[line].substring(0,8).trim();
                let ref = lines[line].substring(25,37).trim();
                let idnumber = lines[line].substring(38,53).trim();
                let SSBstartDate = lines[line].substring(54,64).trim();
                const myArray = SSBstartDate.split("/");
                SSBstartDate = String(myArray[2]) + String(myArray[1]) + String(myArray[0])
                let startDate = moment(SSBstartDate).format('DD/MMM/YYYY');
                // let startDate = moment(SSBstartDate).format('YYYY-MM-DD');
                let message = lines[line].substring(92).trim();
                let status = "Failed"
                const myObj = {
                  "idNumber": idnumber,
                  "payeeCode": payeeCode,
                  "ecNumber": ecNumber,
                  "startDate": startDate,
                  "reference": ref,
                  "message": message,
                  "status": status,
                };
                dataInJSON.push(myObj);
              }
              else if(uploadState === 'rep'){
                let payeeCode = lines[line].substring(40, 45).trim();
                let ecNumber = lines[line].substring(0,8).trim();
                let ref = lines[line].substring(46,58).trim();
                let idnumber = lines[line].substring(59,74).trim();
                let SSBstartDate = lines[line].substring(75,85).trim();
                const myArray = SSBstartDate.split("/");
                SSBstartDate = String(myArray[2]) + String(myArray[1]) + String(myArray[0])
                let startDate = moment(SSBstartDate).format('DD/MMM/YYYY');
                // let startDate = moment(SSBstartDate).format('YYYY-MM-DD');
                let name = lines[line].substring(14,34).trim();
                let bank = lines[line].substring(119).trim();
                let status = "Success"
                const myObj = {
                  "idNumber": idnumber,
                  "payeeCode": payeeCode,
                  "ecNumber": ecNumber,
                  "startDate": startDate,
                  "reference": ref,
                  "name": name,
                  "bank": bank,
                  "status": status,
                };
                dataInJSON.push(myObj);
              }
            }
            // dataInJSON.shift()
            that.uploadedData = dataInJSON;
            // const dataInJSON = dataToJson.txt({data: this.text}).toJson();
            // console.log("Formatted data",dataInJSON)
            // dataInJSON.forEach((node) => node.status = status);
            // dataInJSON.forEach((node) => node.reference = this.sanitizeRef(node.Payee,node.Ref));
            // if (uploadState === 'err'){
            //   dataInJSON.forEach((node) => node.message = this.getMessage(node.Amount));
            // }
            // if (uploadState === 'rep'){
            //   dataInJSON.forEach((node) => node.name = this.getName(node.Names,node.Tran));
            // }

            // that.uploadedData = this.uploadedData.concat(dataInJSON)
          }
          reader.onerror = evt => {
            console.error(evt);
          }
        }

      },
      async DownloadSSB() {
        this.EcocashWait = 5
        this.overlay = true
        await this.DownloadFN()
        this.overlay = false
        this.successDload = true
      },
      sanitizeRef(val1,val2){
        val1 = val1.substring(val1.indexOf(" ") + 1);
        val2 = val2.substring(0, val2.indexOf(' '));
        let val = val1 + '' + val2
        val.trim()
        return val;
      },
      async DownloadFN(){
        window.location.href = "/das/download/" + this.$store.getters.loggedInUser;
      },
      getMessage(val){
        val = val.substring(this.text.indexOf(" ") + 0);
        return val;
      },
      getName(val1,val2){
        let newName = val2 + '' + val1
        newName = newName.split(' ')[0]
        return newName
      },
      async createExcel () {
        const filename = String('transactions_report - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
        const data = this.transactions
        console.log("createExcel method called")
        console.log(this.transactions)
        var ws = xlsx.utils.json_to_sheet(data)
        var wb = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(wb, ws, 'transaction')
        xlsx.writeFile(wb, filename)
      },
      GoTo(val){
        if(val === 'statement'){
          this.$router.push("/user/upload-statement");
        }
        if(val === 'booking'){
          this.$router.push("/user/booking");
        }
        if(val === 'batch'){
          this.$router.push("/user/batch");
        }
        if(val === 'records'){
          this.$router.push("/user/records");
        }
        if(val === 'invoice'){
          this.$router.push("/user/billing");
        }
      },
      handleFileUploads(){
        this.Myfiles = this.$refs.myFile.files;
      },
      async clear() {
        this.statusStats()
        this.dasUpload = false;
        this.uploadedData = [];
        this.files = [];
        this.errorUpload = false;
        this.successUpload = false;
        this.errorDload = false;
        this.successDload = false;
      },
      inProcessing () {
        let processing = this.records.filter((item) => {
          return (item.status === 'Processing')
        })
        const count = processing.length
        return count
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
<style>
.application{
  display: none;
}
.v-dialog--custom {
  width: 100%;
}
</style>
