import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import { mapState } from 'vuex'
Vue.use(Vuetify)

const theme = {
  primary: '#005CB0',
  secondary: '#020932',
  accent: '#020932',
  info: '#005CB0',
  danger: '#be2121',
  success: '#067303',
}


export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  computed: {
    ...mapState(['base_url','institution']),
  },
  created () {

  },

  methods: {
    async ChangeColor(institution) {
      console.log(institution)
    },
  }

  })
