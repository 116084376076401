<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  All deduction requests
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col  cols="12" md="12" style="background-color: whitesmoke">
              <v-card-title>
                <template>
                  <v-btn
                      class="mx-2"
                      dark
                      fab
                      outlined
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <v-btn
                    small
                    outlined
                    color="primary"
                    v-if="false"
                >
                  Filter By Date
                </v-btn>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="records"
                  :search="search"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                      v-if="item.status === 'Success'"
                      color="success"
                      dark
                  >
                    SUCCESS
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Deleted'"
                      color="danger"
                      dark
                  >
                    RECALLED
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Processing'"
                      color="purple"
                      dark
                  >
                    PROCESSING
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Failed'"
                      color="danger"
                      dark
                  >
                    FAILED
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Pending'"
                      color="warning"
                      dark
                  >
                    PENDING
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Rejected'"
                      color="danger"
                      dark
                  >
                    REJECTED
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'PendingApproval'"
                      color="black"
                      dark
                  >
                    PENDING APPROVAL
                  </v-chip>
                </template>
                <template v-slot:item.applicationDate="{ item }">
                  <span>{{ new Date(item.applicationDate).toLocaleString() }}</span>
                </template>
                <template v-slot:item.type="{ item }">
                  <v-chip
                      v-if="item.type === 'N' || item.type === 'n'"
                      color="primary"
                      dark
                  >
                    NEW
                  </v-chip>
                  <v-chip
                      v-if="item.type === 'D' || item.type === 'd'"
                      color="danger"
                      dark
                  >
                    DELETE
                  </v-chip>
                  <v-chip
                      v-if="item.type === 'C' || item.type === 'c'"
                      color="dark"
                      dark
                  >
                    CHANGE
                  </v-chip>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span> {{ item.currency }} {{ ((item.amount / 100).toFixed(2)) }}</span>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      fab
                      x-small
                      color="primary"
                      @click="BookingView(item)"
                  >
                    <v-icon small>
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="BookingDetails"
        persistent
        width="60%"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="clear"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="text-center">
            {{selectedOrganisation}} Booking Information
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <div class="card-body">
            <template>
              <v-card
                  class="mt-0 mb-0 transition-fast-in-fast-out v-card--reveal"
              >
                <template>
                  <v-container>
                    <v-row >
                      <v-col
                          sm="12"
                          md="12"
                          class="pt-0 pb-0"
                      >
                        <div class="table-responsive card-body">
                          <table
                              class="table table-bordered"
                              width="100%"
                              cellspacing="0"
                          >
                            <tbody>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  EC Number
                                </p>
                              </td>
                              <td>
                                {{ selectedECnumber }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  ID Number
                                </p>
                              </td>
                              <td>
                                {{ selectedIDnumber }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Booking Type
                                </p>
                              </td>
                              <td>
                                <v-chip
                                    color="primary"
                                    rounded
                                    elevation="1"
                                >
                                  <v-icon v-if="false">mdi-file-document</v-icon>
                                  &nbsp; <b>{{ selectedType }}</b>
                                </v-chip>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Record Status
                                </p>
                              </td>
                              <td>
                                <v-chip
                                    color="primary"
                                    rounded
                                    elevation="1"
                                >
                                  <v-icon v-if="false">mdi-file-document</v-icon>
                                  &nbsp; <b> {{ selectedStatus }}</b>
                                </v-chip>

                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Booking reference
                                </p>
                              </td>
                              <td>
                                {{ selectedRef }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Deduction Period
                                </p>
                              </td>
                              <td>
                                {{ selectedPeriod }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Installment Amount
                                </p>
                              </td>
                              <td>
                                {{ selectedAmount }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Batch Number
                                </p>
                              </td>
                              <td>
                                {{ selectedBatchnumber }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Message
                                </p>
                              </td>
                              <td>
                                {{ booking_messsage }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Created by
                                </p>
                              </td>
                              <td>
                                {{ owner }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    CLOSE
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
export default {
  name: 'Booking',
  data () {
    return {
      overlay: false,
      search: '',
      isMakerChecker: false,
      valid:false,
      BookingDetails: false,
      records:[],
      headers: [
          { text: 'ID Number', value: 'idNumber' },
          { text: 'BatchID', value: 'batchNumber' },
          { text: 'EC Number', value: 'ecNumber' },
          { text: 'Payee Code', value: 'payeeCode' },
          { text: 'Reference', value: 'reference' },
          { text: 'Type', value: 'type' },
          { text: 'Status', value: 'status' },
          { text: 'Amount', value: 'amount' },
          { text: 'Date Created', value: 'applicationDate' },
          { text: 'View', value: 'action' },
        ],
      rules: {
        required: value => !!value || 'Required.',
        ecNumber: value => {
          const pattern = /^.*$/i
          return pattern.test(value) || 'Invalid ecNumber.'
        },
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'View deduction requests',
          disabled: true,
          href: '',
        },
      ],
      date1: "",

    }
  },
  created () {
    if (this.$store.getters.maker_checker && this.$store.getters.user_type === 'APPROVER') {
      this.isMakerChecker = true
    }
    else{
      this.isMakerChecker = false
    }
    this.getBookings()
    this.getAllMyBookings()
  },
  methods: {
    async getBookings() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/all-bookings/?organisation=' + this.$store.getters.institution)
          .then(res => {
            if (res.status === 200) {
              this.records = res.data
              this.overlay = false
            } else if (res.status === 202) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    async getAllMyBookings() {
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/all-mybookings/?organisation=' + this.$store.getters.institution)
          .then(res => {
            if (res.status === 200) {
              this.records = res.data
              console.log(this.records)
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    async populateDasCodes() {
      const dascode = this.dasCodes.find(item => item.payeeCode === this.payeeCode)
      this.deductionCode = dascode.deductionCode
      this.dasCurrency = dascode.currency
    },
    BookingView(item){
      this.selectedECnumber = item.ecNumber
      this.selectedIDnumber = item.idNumber
      this.selectedAmount = item.currency + '' + (item.amount / 100).toFixed(2)
      this.selectedOrganisation = item.organization
      this.selectedBatchnumber = item.batchNumber
      this.owner = item.created_by
      this.booking_messsage = item.message
      this.selectedPeriod = item.startDate + ' - ' + item.endDate;
      this.selectedRef = item.reference
      this.selectedStatus = item.status
      this.selectedType = item.type
      this.BookingDetails = true
      console.log(item)
    },
    async PreviewFile(){
      this.overlay = true
      let file = this.files[0]
      const reader = new FileReader();
      const that = this
      reader.onload = function(e) {
        const data = e.target.result;
        const workbook = XLSX.read(data, {
          type: 'binary'
        });
        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          const XL_row_object = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[sheetName]);
          const json_object = JSON.stringify(XL_row_object);
          console.log(json_object);
          that.uploadData = XL_row_object;
          return json_object;
        })

      };
      reader.onerror = function(ex) {
        console.log(ex);
      };
      reader.readAsBinaryString(file);
      this.overlay = false
  },
    async UploadFile(){
      this.overlay = true
      this.uploadData.forEach((node) => node.reference = this.GenerateRef(node.accountId));
      this.uploadData.forEach((node) => node.batchNumber = this.GenerateBatchID(this.uploadData));
      console.log(this.uploadData)
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await axios
          .post("/das/post_bookings/?payeeCode=" + this.payeeCode + "&deductionCode=" + this.deductionCode + "&organization=" + this.$store.getters.institution, this.uploadData)
          .then((response) => {
            console.log(response)
            this.overlay = true;
            if (response.status === 200) {
              console.log(response.data);
              this.overlay = false;
              this.successUpload = true;
              // this.$router.push("/user/dashboard").catch(() => {})
            } else if (response.status === 404){
              this.overlay = false;
              console.log('gjkbsadkbasd')
            }
            else {
              this.overlay = false;
              this.errorUpload = true;
              console.log(response)
            }
          })
          .catch((error) => {
            if (error.response.status === 400) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 404) {
              this.overlay = false;
              this.errorUpload = true;
            } else if (error.response.status === 500) {
              this.overlay = false;
              this.errorUpload = true;
            }else{
              this.overlay = false;
              this.errorUpload = true;
            }
          });

    },
    GenerateRef(val){
      val = val.substring(1);
      val = val.slice(0, -2);
      return val;
    },
    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },
    goTo(){
      this.$router.push('/user/batch')
    },
    clear () {
      this.selectedECnumber = '',
      this.selectedIDnumber = '',
      this.selectedAmount = '',
      this.selectedOrganisation = '',
      this.selectedBatchnumber = '',
      this.selectedPeriod = '',
      this.selectedRef = '',
      this.selectedMessage = '',
      this.selectedStatus = '',
      this.selectedType = '',
      this.BookingDetails = false
    },
    async createExcel() {
      const filename = String('AllBookings - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.records
      console.log("createExcel method called")
      console.log(this.records)
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'bookings')
      xlsx.writeFile(wb, filename)
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
