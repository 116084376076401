/* eslint-disable no-trailing-spaces */
/**
 * # API URL
 *
 * this is similar to base URL when its not working,
 * fail safe
 */
ADDRESS = process.env.BASE_URL + process.env.FRONT_END_PORT
module.exports = { API: process.env.VUE_APP_BASE_URL }
// module.exports = { API: 'https://127.0.0.0.1:3001' }
