
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'

Vue.config.productionTip = false
// Vue.prototype.$base_url = process.env.BASE_IP +":"+ process.env.FRONT_END_PORT
Vue.prototype.$base_url = "http://127.0.0.1:8000"
// Vue.prototype.$base_url =  process.env.VUE_APP_BASE_URL
Vue.prototype.$keycloack =  process.env.KEYCLOAK_BASE_URL
new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
