<template>
  <div>
    <v-container
        v-if="!approve_records"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Uploaded batch records
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col  cols="12" md="12" style="background-color: whitesmoke"
            >
              <v-card-title>
                Batch Records
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>
                <v-menu
                    v-if="false"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select Invoice Period"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>

                  <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="secondary"
                        @click="OnDateChange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="batch"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >

                <template v-slot:item.UploadTime="{ item }">
                  <span>{{ new Date(item.UploadTime).toLocaleString() }}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span v-if="item.name === null ">MIC2210010022</span>
                  <span v-else>{{ item.name }}</span>
                </template>
                <template v-slot:item.SSBUploadTime="{ item }">
                  <span v-if="item.batch_status === 'Processing' || item.batch_status === 'Rejected' || item.batch_status === 'Pending' || item.batch_status === 'Deleted' || item.batch_status === 'PendingApproval' || item.batch_status === 'Mixed Approval'">--- --- ---</span>
                  <span v-else>{{ new Date(item.SSBUploadTime).toLocaleString() }}</span>
                </template>
                <template v-slot:item.SSBDownloadTime="{ item }">
                  <span v-if="item.batch_status === 'Pending' || item.batch_status === 'Rejected' || item.batch_status === 'Deleted' || item.batch_status === 'PendingApproval' || item.batch_status === 'Mixed Approval'">--- --- ---</span>
                  <span v-else>{{ new Date(item.SSBDownloadTime).toLocaleString() }}</span>
                </template>
                <template v-slot:item.batch_status="{ item }">
                  <v-chip
                      v-if="item.batch_status === 'Pending' "
                      color="warning"
                      dark
                  >
                    PENDING
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Processing'"
                      color="purple"
                      dark
                  >
                    PROCESSING
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Mixed'"
                      color="primary"
                      dark
                  >
                    MIXED
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Mixed Approval'"
                      color="primary"
                      dark
                  >
                    MIXED APPROVAL
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Deleted'"
                      color="danger"
                      dark
                  >
                    RECALLED
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Success'"
                      color="success"
                      dark
                  >
                    SUCCESS
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Failed'"
                      color="danger"
                      dark
                  >
                    FAILED
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'PendingApproval'"
                      color="black"
                      dark
                  >
                    PENDING APPROVAL
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Rejected'"
                      color="danger"
                      dark
                  >
                    REJECTED
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      v-if="$store.getters.maker_checker && $store.getters.user_type === 'APPROVER' && (item.batch_status === 'PendingApproval'
                      || item.batch_status === 'Mixed Approval' ||  item.batch_status === 'Rejected' ||  item.batch_status === 'Pending') "
                      color="primary"
                      dark
                      class="mb-1 mt-1"
                      small
                      @click="pending_approval(item)"
                  >
                    <v-icon left>
                      mdi-eye
                    </v-icon>
                    View
                  </v-btn>
                  <v-btn
                      v-if="item.batch_status === 'Success' || item.batch_status === 'Failed' || item.batch_status === 'Mixed' "
                      color="primary"
                      dark
                      small
                      @click="DownloadExcel(item)"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Excel
                  </v-btn>
                  <v-btn
                      @click="PromptDelete(item)"
                      v-if="item.batch_status === 'Pending'"
                      color="danger"
                      dark
                      small
                  >
                    <v-icon left>
                      mdi-cancel
                    </v-icon>
                    Recall
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
          <br><br><br>
        </v-col>
      </v-row>
      <v-dialog
          v-model="confirmDelete"
          persistent
          width="400"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Confirm Delete
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #F44336 !important"
            >
              You about to recall batch {{batchToRecall}}
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="5em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="confirmDelete=false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="red"
                      @click="RecallBatch"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="successRecall"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Batch Recalled
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <v-container
        v-if="approve_records"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Approve Batch Records
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col  cols="12" md="12" style="background-color: whitesmoke"
                    v-if="!approver"
            >

              <v-card-title>
                Batch Records
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon dark>
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>
                <v-menu
                    v-if="false"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select Invoice Period"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>

                  <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="secondary"
                        @click="OnDateChange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="batch"
                  :search="search"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >

                <template v-slot:item.applicationDate="{ item }">
                  <span>{{ new Date(item.applicationDate).toLocaleString() }}</span>
                </template>
                <template v-slot:item.name="{ item }">
                  <span v-if="item.name === null ">MIC2210010022</span>
                  <span v-else>{{ item.name }}</span>
                </template>
                <template v-slot:item.ssb_upload="{ item }">
                  <span v-if="item.batch_status === 'Processing' || item.batch_status === 'Pending' || item.batch_status === 'Deleted' || item.batch_status === 'PendingApproval'">--- --- ---</span>
                  <span v-else>{{ new Date(item.ssb_upload).toLocaleString() }}</span>
                </template>
                <template v-slot:item.ssb_download="{ item }">
                  <span v-if="item.batch_status === 'Pending' || item.batch_status === 'Deleted' || item.batch_status === 'PendingApproval'">--- --- ---</span>
                  <span v-else>{{ new Date(item.ssb_download).toLocaleString() }}</span>
                </template>
                <template v-slot:item.batch_status="{ item }">
                  <v-chip
                      v-if="item.batch_status === 'Pending' "
                      color="warning"
                      dark
                  >
                    PENDING
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Processing'"
                      color="purple"
                      dark
                  >
                    PROCESSING
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Mixed'"
                      color="primary"
                      dark
                  >
                    MIXED
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Deleted'"
                      color="danger"
                      dark
                  >
                    RECALLED
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Success'"
                      color="success"
                      dark
                  >
                    SUCCESS
                  </v-chip>
                  <v-chip
                      v-if="item.batch_status === 'Failed'"
                      color="danger"
                      dark
                  >
                    FAILED
                  </v-chip>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                      v-if="item.batch_status === 'Success' || item.batch_status === 'Failed' || checkApprovalState(item.SSBDownloadTime,item.SSBUploadTime) === false"
                      color="primary"
                      dark
                      small
                      @click="DownloadExcel(item)"
                  >
                    <v-icon left>
                      mdi-download
                    </v-icon>
                    Excel
                  </v-btn>
                  <v-btn
                      @click="PromptDelete(item)"
                      v-if="item.batch_status === 'Pending'"
                      color="danger"
                      dark
                      small
                  >
                    <v-icon left>
                      mdi-cancel
                    </v-icon>
                    Recall
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col  cols="12" md="12" style="background-color: whitesmoke"
                    v-if="approver"
            >
              <v-btn
                  class="ma-2"
                  outlined
                  color="primary"
                  @click="clear()"
              >
                <v-icon left>
                  mdi-arrow-left
                </v-icon>
                BACK TO BATCH LIST
              </v-btn>
              <v-card-title>
                Pending Approval
                <v-spacer></v-spacer>
                <template v-if="selectedToApprove.length > 0">
                  <v-btn
                      class="mx-2"
                      dark
                      small
                      color="success"
                      @click="postApproval('APPROVE')"
                  >
                    <v-icon small dark>
                      mdi-check
                    </v-icon>
                     APPROVE
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-btn
                      class="mx-2"
                      dark
                      small
                      color="danger"
                      @click="postApproval('REJECT')"
                  >
                    <v-icon small dark>
                      mdi-cancel
                    </v-icon>
                    REJECT
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>
                <v-menu
                    v-if="false"
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="Select Invoice Period"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>

                  <v-date-picker
                      v-model="date"
                      type="month"
                      no-title
                      scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="secondary"
                        @click="OnDateChange"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="approvalHeaders"
                  :items="recordsToApprove"
                  :search="search"
                  v-model="selectedToApprove"
                  show-select
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                      v-if="item.status === 'Pending'"
                      color="warning"
                      dark
                  >
                    {{ item.status }}
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Rejected'"
                      color="danger"
                      dark>
                    {{ item.status }}
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'PendingApproval'"
                      color="gray"
                      dark>
                    {{ item.status }}
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Processing'"
                      color="purple"
                      dark>
                    {{ item.status }}
                  </v-chip>
                </template>
                <template v-slot:item.applicationDate="{ item }">
                  <span>{{ new Date(item.applicationDate).toLocaleString() }}</span>
                </template>
                <template v-slot:item.type="{ item }">
                  <v-chip
                      v-if="item.type === 'N' || item.type === 'n'"
                      color="primary"
                      dark
                  >
                    NEW
                  </v-chip>
                  <v-chip
                      v-if="item.type === 'D' || item.type === 'd'"
                      color="danger"
                      dark
                  >
                    DELETE
                  </v-chip>
                  <v-chip
                      v-if="item.type === 'C' || item.type === 'c'"
                      color="dark"
                      dark
                  >
                    CHANGE
                  </v-chip>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span> {{ item.currency }} {{ ((item.amount / 100).toFixed(2)) }}</span>
                </template>
              </v-data-table>

            </v-col>
          </v-row>
          <br><br><br>
        </v-col>
      </v-row>
      <v-dialog
          v-model="confirmDelete"
          persistent
          width="400"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Confirm Delete
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #F44336 !important"
            >
              You about to recall batch {{batchToRecall}}
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="5em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="confirmDelete=false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                      color="red"
                      @click="RecallBatch"
                  >
                    Confirm
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="successRecall"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Batch Recalled
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
import {mapState} from "vuex";
export default {
  name: 'Batch',
  data () {
    return {
      approve_records: false,
      batch: [],
      selectedToApprove: [],
      approverbatch: [],
      recordsToApprove: [],
      search: '',
      batchToRecall: '',
      approver: false,
      overlay: false,
      successRecall: false,
      valid:false,
      confirmDelete: false,
      sortBy: 'applicationDate',
      sortDesc: true,
      headers: [
        { text: 'Created by', value: 'Owner' },
        { text: 'BatchID', value: 'BatchNumber' },
        { text: 'No of Records', value: 'Count' },
        { text: 'Response', value: 'batch_status' },
        { text: 'Booked', value: 'UploadTime' },
        { text: 'Processing Start', value: 'SSBDownloadTime' },
        { text: 'Processing End', value: 'SSBUploadTime' },
        { text: '', value: 'actions' },
      ],
      approvalHeaders: [
        { text: 'ID Number', value: 'idNumber' },
        { text: 'BatchID', value: 'batchNumber' },
        { text: 'EC Number', value: 'ecNumber' },
        { text: 'Payee Code', value: 'payeeCode' },
        { text: 'Reference', value: 'reference' },
        { text: 'Type', value: 'type' },
        { text: 'Status', value: 'status' },
        { text: 'Amount', value: 'amount' },
        { text: 'Date Created', value: 'applicationDate' },
      ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'Uploaded batch',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  computed: {
    ...mapState(['maker_checker','user_type']),
  },
  created () {
    if(this.maker_checker && this.user_type === 'APPROVER'){
      this.approver = true
    }
    else{
      this.approver = false
    }
    this.getBatch(1)
    this.getBatch(2)

  },
  methods: {
    async getBatch(request_id) {
      if(request_id == 1){
        this.overlay = true
      }
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/batch/' + request_id + '/?organisation=' + this.$store.getters.institution)
          .then(res => {
            if (res.status === 200) {
              this.batch = res.data
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    async getApproverBatch() {
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/approver-batch/?organisation=' + this.$store.getters.institution, { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.approverbatch = res.data
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            }
            else if (res.status === 401) {
              this.overlay = false
            }
            else {
              this.overlay = false
            }
          })
    },
    async createExcel() {
      const filename = String('BatchSummary - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.batch
      console.log("createExcel method called")
      console.log(this.batch)
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'BatchSummary')
      xlsx.writeFile(wb, filename)
    },
    pending_approval(val){
      console.log(val)
      this.overlay = true
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/get-approver-records/' + val.BatchNumber + '/', { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.recordsToApprove = res.data
              this.approve_records = true
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {
              this.overlay = false
            }
          })
    },
    GenerateBatchID(val){
      const Org = this.$store.getters.institution.substring(0, 3).toUpperCase();
      const TodayDate = moment().format("YYMMDDms");
      const BatchID = Org + TodayDate + val.length
      return BatchID;
    },
    clear () {
      this.getBatch(1)
      this.getBatch(2)
      this.approve_records = false
      this.selectedToApprove = []
      this.batchToRecall = ''
      this.successRecall = false
      this.getBatch()
    },
    checkApprovalState(val1, val2){
      let formattedDate1 = moment(val1).format('YYYYMMDDHHmm')
      let formattedDate2 = moment(val2).format('YYYYMMDDHHmm')
      if (formattedDate1 === formattedDate2){
        return true
      }
      else{
        return false
      }

    },
    postApproval(val){
      this.overlay = true
      let batchToApprove = {"BatchNumber": this.selectedToApprove[0].batchNumber }
      this.selectedToApprove.forEach((node) => delete node.applicationDate);
      this.selectedToApprove.forEach((node) => delete node.bank);
      this.selectedToApprove.forEach((node) => delete node.batchNumber);
      this.selectedToApprove.forEach((node) => delete node.businesKey);
      this.selectedToApprove.forEach((node) => delete node.created_by);
      this.selectedToApprove.forEach((node) => delete node.currency);
      this.selectedToApprove.forEach((node) => delete node.date_created);
      this.selectedToApprove.forEach((node) => delete node.date_updated);
      this.selectedToApprove.forEach((node) => delete node.deductionCode);
      this.selectedToApprove.forEach((node) => delete node.ecNumber);
      this.selectedToApprove.forEach((node) => delete node.endDate);
      this.selectedToApprove.forEach((node) => delete node.idNumber);
      this.selectedToApprove.forEach((node) => delete node.message);
      this.selectedToApprove.forEach((node) => delete node.name);
      this.selectedToApprove.forEach((node) => delete node.payeeCode);
      this.selectedToApprove.forEach((node) => delete node.raisedRequest);
      this.selectedToApprove.forEach((node) => delete node.recalled);
      this.selectedToApprove.forEach((node) => delete node.reference);
      this.selectedToApprove.forEach((node) => delete node.requestServiced);
      this.selectedToApprove.forEach((node) => delete node.result);
      this.selectedToApprove.forEach((node) => delete node.ssb_download);
      this.selectedToApprove.forEach((node) => delete node.ssb_upload);
      this.selectedToApprove.forEach((node) => delete node.startDate);
      this.selectedToApprove.forEach((node) => delete node.status);
      this.selectedToApprove.forEach((node) => delete node.totalAmount);
      this.selectedToApprove.forEach((node) => delete node.type);
      this.selectedToApprove.forEach((node) => delete node.uploadType);
      this.selectedToApprove.forEach((node) => delete node.user_id);
      this.selectedToApprove.forEach((node) => delete node.accountId);
      this.selectedToApprove.forEach((node) => delete node.amount);
      this.selectedToApprove.forEach((node) => delete node.isApproved);
      this.selectedToApprove.forEach((node) => delete node.organisation);
      if(val === 'APPROVE'){
        this.selectedToApprove.forEach((node) => node.status = 'Pending');
      }
      else if(val === 'REJECT'){
        this.selectedToApprove.forEach((node) => node.status = 'Rejected');
      }
      const JWTToken = this.$store.getters.accessToken
      axios.patch('/das/approver-status/', this.selectedToApprove, { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.pending_approval(batchToApprove)
              this.overlay = false
            } else if (res.status === 404) {
              this.overlay = false
            } else {

              this.overlay = false
            }
          })
    },

    PromptDelete(val){
      this.batchToRecall = val.BatchNumber
      this.confirmDelete = true
    },
    RecallBatch(){
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/recall/?id=' + this.batchToRecall, { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              this.confirmDelete = false
              this.successRecall = true
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    DownloadExcel(val){
      console.log(val.id)
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/batch-bookings/' + val.id, { headers: { Authorization: `Bearer ${JWTToken}` } })
          .then(res => {
            if (res.status === 200) {
              const batch_records = res.data
              // const batch_records = all_records.filter((item) => {return (item.batchNumber === val.name)})
              batch_records.forEach((node) => delete node.batchNumber);
              batch_records.forEach((node) => delete node.accountID);
              batch_records.forEach((node) => delete node.businesKey);
              batch_records.forEach((node) => delete node.accountID);
              batch_records.forEach((node) => delete node.currency);
              batch_records.forEach((node) => delete node.date_created);
              batch_records.forEach((node) => delete node.ssb_download);
              batch_records.forEach((node) => delete node.ssb_upload);
              batch_records.forEach((node) => delete node.date_updated);
              batch_records.forEach((node) => delete node.applicationDate);
              batch_records.forEach((node) => delete node.recalled);
              batch_records.forEach((node) => delete node.isApproved);
              batch_records.forEach((node) => delete node.requestServiced);
              batch_records.forEach((node) => delete node.raisedRequest);
              batch_records.forEach((node) => delete node.organization);
              batch_records.forEach((node) => delete node.originalAmount);
              batch_records.forEach((node) => delete node.batchID);
              batch_records.forEach((node) => delete node.user_id);
              batch_records.forEach((node) => delete node.TotalAmount);
              batch_records.forEach((node) => node.Recid = this.generateRef(node.id));
              batch_records.forEach((node) => node.DeductionCode = this.generateDeduction(node.deductionCode,node.payeeCode));
              if(this.$store.getters.institution === 'MICROPLAN' ){
                batch_records.forEach((node) => node.Reference = node.accountId);
              }
              else{
                batch_records.forEach((node) => node.Reference = node.reference);
              }
              batch_records.forEach((node) => node.Reference = node.accountId);
              batch_records.forEach((node) => node.IdNumber = node.idNumber);
              batch_records.forEach((node) => node.EcNumber = node.ecNumber);
              batch_records.forEach((node) => node.Type = this.defineType(node.type));
              batch_records.forEach((node) => node.Status = this.upperStatus(node.status));
              batch_records.forEach((node) => node.StartDate = node.startDate);
              batch_records.forEach((node) => node.EndDate = node.endDate);
              batch_records.forEach((node) => node.Amount = this.FormatAmount(node.amount));
              batch_records.forEach((node) => node.TotalAmount = '');
              batch_records.forEach((node) => node.Name = node.name);
              batch_records.forEach((node) => node.Bank = '');
              batch_records.forEach((node) => node.BankAccount = node.bank);
              batch_records.forEach((node) => node.Message = node.message);
              batch_records.forEach((node) => delete node.status);
              batch_records.forEach((node) => delete node.amount);
              batch_records.forEach((node) => delete node.bank);
              batch_records.forEach((node) => delete node.deductionCode);
              batch_records.forEach((node) => delete node.ecNumber);
              batch_records.forEach((node) => delete node.endDate);
              batch_records.forEach((node) => delete node.id);
              batch_records.forEach((node) => delete node.idNumber);
              batch_records.forEach((node) => delete node.message);
              batch_records.forEach((node) => delete node.name);
              batch_records.forEach((node) => delete node.payeeCode);
              batch_records.forEach((node) => delete node.reference);
              batch_records.forEach((node) => delete node.result);
              batch_records.forEach((node) => delete node.startDate);
              batch_records.forEach((node) => delete node.totalAmount);
              batch_records.forEach((node) => delete node.type);
              batch_records.forEach((node) => delete node.accountId);
              const filename = String(val.BatchNumber + ' - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
              const data = batch_records
              var ws = xlsx.utils.json_to_sheet(data)
              var wb = xlsx.utils.book_new()
              xlsx.utils.book_append_sheet(wb, ws, val.name)
              xlsx.writeFile(wb, filename)
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })

    },
    generateRef(val){
      let ref = 'REC' + val
      return ref;
    },
    FormatAmount(val){
      let amount =  (val / 100).toFixed(2)
      return amount;
    },
    generateDeduction(val1,val2){
      let ded = val1 + '' + val2
      return ded;
    },
    formatDate(val){
      val.replace(/\s/g, '');
      let finalDate = moment(val).format(' ')
      return finalDate;
    },
    defineType(val){
      let type = 'NONE'
      if (val == 'N' || val == 'n' ){
        type = 'NEW'
      }
      if (val == 'D' || val == 'd' ){
        type = 'DELETE'
      }
      if (val == 'C' || val == 'c' ){
        type = 'CHANGE'
      }
      return type;
    },
    upperStatus(val){
      let status = 'NONE'
      if(val == 'Failed'){
        status = 'FAILED'
      }
      if(val == 'Success'){
        status = 'SUCCESS'
      }
      return status
    }
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
