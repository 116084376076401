import axios from "axios";


export function getUserIP () {
    axios.get('https://api.ipify.org?format=json').then(response => {
         if(response.status === 200) {
             console.log(response.data.ip)
             return response.data.ip
         }
         else {
             console.log("Error in fetching IP")
             return {}
         }
    })
}
