<template>
  <div>
    <v-container
        v-if="true"
        id="user-profile"
        fluid
        tag="section"
    >
      <v-row
          justify="center"
      >
        <v-col
            cols="12"
            md="12"
        >
          <v-row>
            <v-col
                cols="12"
                md="12"
            >
              <v-card-title>
                <div>
                  <v-breadcrumbs :items="links" />
                </div>
                <v-spacer />
                <div
                    class="text-h3 text-center font-weight-medium"
                >
                  Organisation filtered requests
                </div>
                <v-spacer />
                <v-spacer />
              </v-card-title>
            </v-col>
          </v-row>
          <v-row class="mt-2">
            <v-col cols="1" md="1" style="justify-content: center">

            </v-col>
            <v-col  cols="10" md="10" style="background-color: whitesmoke">

              <v-card-title>
                Requests Count
                <v-spacer></v-spacer>
                <template>
                  <v-btn
                      class="mx-2"
                      dark
                      fab
                      outlined
                      small
                      color="primary"
                      @click="createExcel()"
                  >
                    <v-icon>mdi-download</v-icon>
                  </v-btn>
                </template>
                <v-spacer></v-spacer>
                <br>
                <br>

                <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="date"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateRangeText"
                        label="Select Filter Period"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>

                  </template>

                  <v-date-picker
                      v-model="dates"
                      range
                  >
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="primary"
                        @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                        text
                        color="secondary"
                        @click="FilterRecords()"
                    >
                      Filter
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                <v-spacer></v-spacer>
                <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                ></v-text-field>
              </v-card-title>
              <v-data-table
                  :headers="headers"
                  :items="records"
                  :search="search"
              >
                <template v-slot:item.status="{ item }">
                  <v-chip
                      v-if="item.status === 'Success'"
                      color="success"
                      dark
                  >
                    SUCCESS
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Deleted'"
                      color="danger"
                      dark
                  >
                    RECALLED
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Processing'"
                      color="purple"
                      dark
                  >
                    PROCESSING
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Failed'"
                      color="danger"
                      dark
                  >
                    FAILED
                  </v-chip>
                  <v-chip
                      v-if="item.status === 'Pending'"
                      color="warning"
                      dark
                  >
                    PENDING
                  </v-chip>
                </template>
                <template v-slot:item.applicationDate="{ item }">
                  <span>{{ new Date(item.applicationDate).toLocaleString() }}</span>
                </template>
                <template v-slot:item.type="{ item }">
                  <v-chip
                      v-if="item.type === 'N' || item.type === 'n'"
                      color="primary"
                      dark
                  >
                    NEW
                  </v-chip>
                  <v-chip
                      v-if="item.type === 'D' || item.type === 'd'"
                      color="danger"
                      dark
                  >
                    DELETE
                  </v-chip>
                  <v-chip
                      v-if="item.type === 'C' || item.type === 'c'"
                      color="dark"
                      dark
                  >
                    CHANGE
                  </v-chip>
                </template>
                <template v-slot:item.amount="{ item }">
                  <span> {{ item.currency }} {{ ((item.amount / 100).toFixed(2)) }}</span>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-btn
                      fab
                      x-small
                      color="primary"
                      @click="BookingView(item)"
                  >
                    <v-icon small>
                      mdi-eye
                    </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog
        v-model="BookingDetails"
        persistent
        width="50%"
    >
      <v-card>
        <v-toolbar
            dark
            color="primary"
        >
          <v-btn
              icon
              dark
              @click="clear"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-spacer />
          <v-toolbar-title class="text-center">
            {{selectedOrganisation}} Booking Information
          </v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <div class="card-body">
            <template>
              <v-card
                  class="mt-0 mb-0 transition-fast-in-fast-out v-card--reveal"
              >
                <template>
                  <v-container>
                    <v-row >
                      <v-col
                          sm="12"
                          md="12"
                          class="pt-0 pb-0"
                      >
                        <div class="table-responsive card-body">
                          <table
                              class="table table-bordered"
                              width="100%"
                              cellspacing="0"
                          >
                            <tbody>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  EC Number
                                </p>
                              </td>
                              <td>
                                {{ selectedECnumber }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  ID Number
                                </p>
                              </td>
                              <td>
                                {{ selectedIDnumber }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Booking Type
                                </p>
                              </td>
                              <td>
                                <v-chip
                                    color="primary"
                                    rounded
                                    elevation="1"
                                >
                                  <v-icon v-if="false">mdi-file-document</v-icon>
                                  &nbsp; <b>{{ selectedType }}</b>
                                </v-chip>
                              </td>
                            </tr>

                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Record Status
                                </p>
                              </td>
                              <td>
                                <v-chip
                                    color="primary"
                                    rounded
                                    elevation="1"
                                >
                                  <v-icon v-if="false">mdi-file-document</v-icon>
                                  &nbsp; <b> {{ selectedStatus }}</b>
                                </v-chip>

                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Booking reference
                                </p>
                              </td>
                              <td>
                                {{ selectedRef }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Deduction Period
                                </p>
                              </td>
                              <td>
                                {{ selectedPeriod }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Installment Amount
                                </p>
                              </td>
                              <td>
                                {{ selectedAmount }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Batch Number
                                </p>
                              </td>
                              <td>
                                {{ selectedBatchnumber }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Message
                                </p>
                              </td>
                              <td>
                                {{ booking_messsage }}
                              </td>
                            </tr>
                            <tr>
                              <td colspan="2">
                                <p class="text-left font-weight-light">
                                  Created by
                                </p>
                              </td>
                              <td>
                                {{ owner }}
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    CLOSE
                  </v-btn>
                </v-card-actions>
              </v-card>
            </template>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
// import { mapState } from 'vuex'
import axios from 'axios'
import { API } from '../../../api'
import moment from 'moment'
import dataToJson from "data-to-json";
import * as XLSX from "xlsx";
import xlsx from "xlsx";
export default {
  name: 'Booking',
  data () {
    return {
      menu: false,
      overlay: false,
      search: '',
      dates: [],
      date: new Date().toISOString().substr(0, 7),
      valid:false,
      BookingDetails: false,
      records:[],
      headers: [
          { text: 'Organisation', value: 'organisation' },
          { text: 'Count', value: 'count' },
        ],
      rules: {
        required: value => !!value || 'Required.',
        counter: value => value.length >= 10 || 'Max 10 characters',
        counter_id: value => value.length >= 11 || 'Max 11 characters',
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
      },
      links: [
        {
          text: 'SSB',
          disabled: false,
          href: 'generate',
        },
        {
          text: 'View deduction requests',
          disabled: true,
          href: '',
        },
      ],
    }
  },
  created () {
   this.getBookings()
  },
  computed: {
    dateRangeText () {
      return this.dates.join(' ~ ')
    },
  },
  methods: {
    async getBookings() {
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/get-organisationcount/?range=initial')
          .then(res => {
            if (res.status === 200) {
              this.records = res.data
              console.log(this.records)
            } else if (res.status === 202) {
              console.log('202')
            } else {
              console.log('error')
            }
          })
    },
    BookingView(item){
      this.selectedECnumber = item.ecNumber
      this.selectedIDnumber = item.idNumber
      this.selectedAmount = item.currency + '$' + (item.amount / 100)
      this.selectedOrganisation = item.organization
      this.selectedBatchnumber = item.batchNumber
      this.owner = item.created_by
      this.booking_messsage = item.message
      this.selectedPeriod = item.startDate + ' - ' + item.endDate;
      this.selectedRef = item.reference
      this.selectedStatus = item.status
      this.selectedType = item.type
      this.BookingDetails = true
      console.log(item)
    },
    FilterRecords(){
      const JWTToken = this.$store.getters.accessToken
      axios.get('/das/get-organisationcount/?range=' + this.dates )
          .then(res => {
            if (res.status === 200) {
              this.records = res.data
              console.log(this.records)
            } else if (res.status === 202) {
              this.records = []
              console.log('202')
            } else {
              this.records = []
              console.log('error')
            }
          })
      this.menu = false
    },
    goTo(){
      this.$router.push('/user/batch')
    },
    clear () {
      this.selectedECnumber = '',
      this.selectedIDnumber = '',
      this.selectedAmount = '',
      this.selectedOrganisation = '',
      this.selectedBatchnumber = '',
      this.selectedPeriod = '',
      this.selectedRef = '',
      this.selectedMessage = '',
      this.selectedStatus = '',
      this.selectedType = '',
      this.BookingDetails = false
    },
    async createExcel() {
      const filename = String('AllBookings - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
      const data = this.records
      console.log("createExcel method called")
      console.log(this.records)
      var ws = xlsx.utils.json_to_sheet(data)
      var wb = xlsx.utils.book_new()
      xlsx.utils.book_append_sheet(wb, ws, 'bookings')
      xlsx.writeFile(wb, filename)
    },
  },
}
</script>
<style>
.my-input input{
  text-transform: uppercase
}
</style>
