<template>
  <div>
    <v-container
        id="dashboard"
        fluid
        tag="section"
    >
      <div>
        <v-row>
          <v-col
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-account-circle-outline"
                title="Users"
                :value="String(success)"
                sub-icon="mdi-check-all"
                sub-icon-color="green"
                sub-text="Total users"
            />
          </v-col>
          <v-col
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
                color="primary"
                icon="mdi-progress-clock"
                title="Active"
                :value="String(processing)"
                sub-icon="mdi-alert"
                sub-icon-color="yellow"
                sub-text="Active users"
            />
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
              color="primary"
              icon="mdi-download-off-outline"
              title="Disabled"
              :value="String(pending)"
              sub-icon="mdi-eye-off"
              sub-icon-color="purple"
              sub-text="Disabled users"
            />
          </v-col>
          <v-col
              v-if="!SSBstate"
              cols="12"
              sm="6"
              lg="3"
          >
            <base-material-stats-card
              color="primary"
              icon="mdi-close-circle-outline"
              title="Admins"
              :value="String(failed)"
              sub-icon="mdi-close"
              sub-icon-color="red"
              sub-text="Failed bookings"
            />
          </v-col>
          <v-col  cols="12" md="12" style="background-color: whitesmoke">

            <v-card-title>
              Users

              <template>
              </template>
              <v-spacer></v-spacer>
              <br>
              <v-spacer></v-spacer>
              <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
              ></v-text-field>
            </v-card-title>
            <v-data-table
                :headers="headers"
                :items="users"
                :search="search"
            >
              <template v-slot:item.created_at="{ item }">
                <span>{{ new Date(item.created_at).toLocaleString() }}</span>
              </template>
              <template v-slot:item.view="{ item }">
                <v-btn
                    fab
                    x-small
                    color="primary"
                    @click="EditUser(item)"
                >
                  <v-icon small>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.is_active="{ item }">
                <v-chip color="primary" v-if="item.is_active == true">
                  ACTIVE
                </v-chip>
                <v-chip color="red" v-if="item.is_active == false">
                  INACTIVE
                </v-chip>
              </template>
            </v-data-table>

          </v-col>
        </v-row>
      </div>
      <v-dialog
          v-model="successUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Upload Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="errorUpload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Upload Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #800808"
            >
              References do not exist
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/error.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Resubmit
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="successDload"
          persistent
          width="350"
      >
        <v-card>
          <v-toolbar
              dark
              color="primary"
          >
            <v-toolbar-title class="text-center justify-content-center" v-if="false">
              Download Status
            </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <div class="text-center justify-center">
            <br>
            <h2
                style="color: #4caf50"
            >
              Download Successful
            </h2>
            <v-row class="mr-2 ml-2 pt-0 pb-0">
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <div>
                    <v-img
                        src="@/assets/success.svg"
                        width="8em"
                    />
                  </div>
                </v-card-actions>
              </v-col>
              <v-col
                  sm="12"
                  md="12"
                  lg="12"
              >
                <v-card-actions
                    class="justify-center"
                >
                  <v-btn
                      color="primary"
                      @click="clear"
                  >
                    Done
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="text-center">
      <v-overlay :value="overlay">
        <v-progress-circular
            indeterminate
            size="64"
        />
      </v-overlay>
    </div>
  </div>
</template>
<script>
  import { mapState } from 'vuex'
  import axios from 'axios'
  import moment from 'moment'
  import xlsx from 'xlsx'
  const dataToJson = require("data-to-json");

  export default {
    name: 'Dashboard',
    data () {
      return {
        DownloadUrl: 'http://127.0.0.1:8000/ssbdownload/',
        processing: '0',
        files:[],
        failed: '0',
        success: '0',
        pending: '0',
        SSBProcessed: '0',
        SSBProcessing: '0',
        SSBPending: '0',
        SSBSuccess: '0',
        dasUpload: false,
        users: [],
        uploadedData: [],
        errorUpload:false,
        successUpload:false,
        successDload: false,
        errorDload: false,
        EcocashWait: 0,
        date: new Date().toISOString().substr(0, 7),
        modal: false,
        year: new Date().getFullYear(),
        isloading: false,
        search: '',
        overlay: false,
        SSBstate: false,
        headers: [
          {
            sortable: true,
            text: 'NAME',
            value: 'fullname',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'USERNAME',
            value: 'id_number',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'EMAIL',
            value: 'email',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'STATUS',
            value: 'is_active',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'ORGANISATION',
            value: 'organisation_name',
            class: 'primary--text title',
          },
          {
            sortable: true,
            text: 'CREATED',
            value: 'created_at',
            class: 'primary--text title',
          },
        ],
      };
    },
    created() {
      this.getUsers()
      console.log("User IP",this.user_ip)
    },
    computed: {
      ...mapState(['is_auditor', 'access_token', 'base_url', 'is_fbcmanager','user_ip']),
    },
    methods: {
      async getUsers() {
        axios.get('/das/auth/get_users/')
            .then(res => {
              if (res.status === 200) {
                this.users = res.data
                console.log(this.users)
                // this.processing = this.records.filter((item) => {return (item.status === 'Processing')}).length
                // this.success = this.records.filter((item) => {return (item.status === 'Success')}).length
                // this.pending = this.records.filter((item) => {return (item.status === 'Pending')}).length
                // this.failed = this.records.filter((item) => {return (item.status === 'Failed')}).length
              } else if (res.status === 202) {
                console.log('202')
              } else {
                console.log('error')
              }
            })
      },
      async DownloadFN(){
        window.location.href = "/das/download/";
      },
      getMessage(val){
        val = val.substring(this.text.indexOf(" ") + 0);
        return val;
      },
      getName(val1,val2){
        let newName = val2 + '' + val1
        newName = newName.split(' ')[0]
        return newName
      },
      async createExcel () {
        const filename = String('transactions_report - ' + moment(Date()).format('MM/DD/YYYY') + '.xlsx')
        const data = this.transactions
        console.log("createExcel method called")
        console.log(this.transactions)
        var ws = xlsx.utils.json_to_sheet(data)
        var wb = xlsx.utils.book_new()
        xlsx.utils.book_append_sheet(wb, ws, 'transaction')
        xlsx.writeFile(wb, filename)
      },
      GoTo(val){

        if(val === 'statement'){
          this.$router.push("/user/upload-statement");
        }
        if(val === 'booking'){
          this.$router.push("/user/booking");
        }
        if(val === 'batch'){
          this.$router.push("/user/batch");
        }
        if(val === 'records'){
          this.$router.push("/user/records");
        }
      },
      async clear() {
        await this.getSSBBookings()
        this.dasUpload = false;
        this.uploadedData = [];
        this.files = [];
        this.errorUpload = false;
        this.successUpload = false;
        this.errorDload = false;
        this.successDload = false;
      },
      complete (index) {
        this.list[index] = !this.list[index]
      },
    },
  }
</script>
<style>
.application{
  display: none;
}
.v-dialog--custom {
  width: 100%;
}
</style>
